/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";
import React, { FC, Fragment, useState } from "react";
import {
    Area,
    CartesianGrid,
    Dot,
    Line,
    AreaChart as ReChartsAreaChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import { classMerge } from "../../utils/styleUtils";
import { BaseChartProps } from "../common/types";
import {
    defaultValueFormatter,
    getYAxisDomain,
    hasOnlyOneValueForThisKey,
} from "../common/utils";
import { CurveType } from "../lineChart/LineChart";

export interface IAreaChartProps extends BaseChartProps {
    stack?: boolean;
    curveType?: CurveType;
    connectNulls?: boolean;
    intervalType?: string;
}

interface ActiveDot {
    index?: number;
    dataKey?: string;
}

export const AreaChart: FC<IAreaChartProps> = React.forwardRef<
    HTMLDivElement,
    IAreaChartProps
>((props, ref) => {
    const {
        data = [],
        categories = [],
        index,
        stack = false,
        colors = [],
        valueFormatter = defaultValueFormatter,
        startEndOnly = false,
        showXAxis = true,
        showYAxis = true,
        yAxisWidth = 56,
        intervalType = "equidistantPreserveStart",
        showAnimation = false,
        animationDuration = 900,
        showTooltip = true,
        showLegend = true,
        showGridLines = true,
        showGradient = true,
        autoMinValue = false,
        curveType = "natural",
        minValue,
        maxValue,
        connectNulls = false,
        allowDecimals = true,
        noDataText,
        className,
        onValueChange,
        // customTooltip,
        ...other
    } = props;
    // const CustomTooltip = customTooltip;
    const [legendHeight, setLegendHeight] = useState(60);
    const [activeDot, setActiveDot] = useState<ActiveDot | undefined>(
        undefined
    );
    const [activeLegend, setActiveLegend] = useState<string | undefined>(
        undefined
    );
    // const categoryColors = constructCategoryColors(categories, colors);

    const yAxisDomain = getYAxisDomain(autoMinValue, minValue, maxValue);
    const hasOnValueChange = !!onValueChange;

    function onDotClick(itemData: any, event: React.MouseEvent) {
        event.stopPropagation();

        if (!hasOnValueChange) return;
        if (
            (itemData.index === activeDot?.index &&
                itemData.dataKey === activeDot?.dataKey) ||
            (hasOnlyOneValueForThisKey(data, itemData.dataKey) &&
                activeLegend &&
                activeLegend === itemData.dataKey)
        ) {
            setActiveLegend(undefined);
            setActiveDot(undefined);
            onValueChange?.(null);
        } else {
            setActiveLegend(itemData.dataKey);
            setActiveDot({
                index: itemData.index,
                dataKey: itemData.dataKey,
            });
            onValueChange?.({
                eventType: "dot",
                categoryClicked: itemData.dataKey,
                ...itemData.payload,
            });
        }
    }

    function onCategoryClick(dataKey: string) {
        if (!hasOnValueChange) return;
        if (
            (dataKey === activeLegend && !activeDot) ||
            (hasOnlyOneValueForThisKey(data, dataKey) &&
                activeDot &&
                activeDot.dataKey === dataKey)
        ) {
            setActiveLegend(undefined);
            onValueChange?.(null);
        } else {
            setActiveLegend(dataKey);
            onValueChange?.({
                eventType: "category",
                categoryClicked: dataKey,
            });
        }
        setActiveDot(undefined);
    }
    return (
        <div
            ref={ref}
            className={classMerge("w-full h-80", className)}
            {...other}
        >
            <ResponsiveContainer className="h-full w-full">
                {data?.length ? (
                    <ReChartsAreaChart
                        data={data}
                        onClick={
                            hasOnValueChange && (activeLegend || activeDot)
                                ? () => {
                                      setActiveDot(undefined);
                                      setActiveLegend(undefined);
                                      onValueChange?.(null);
                                  }
                                : undefined
                        }
                    >
                        {showGridLines ? (
                            <CartesianGrid
                                className={classMerge(
                                    // common
                                    "stroke-1",
                                    // light
                                    "stroke-secondary-foreground/50",
                                    // dark
                                    "dark:stroke-dark-tremor-border"
                                )}
                                horizontal={true}
                                vertical={false}
                            />
                        ) : null}
                        <XAxis
                            padding={{ left: 20, right: 20 }}
                            hide={!showXAxis}
                            dataKey={index}
                            tick={{ transform: "translate(0, 6)" }}
                            ticks={
                                startEndOnly
                                    ? [
                                          data[0][index],
                                          data[data.length - 1][index],
                                      ]
                                    : undefined
                            }
                            fill=""
                            stroke=""
                            className={classMerge(
                                // common
                                "text-sm",
                                // light
                                "fill-tremor-content",
                                // dark
                                "dark:fill-dark-tremor-content"
                            )}
                            // interval={
                            //     startEndOnly
                            //         ? "preserveStartEnd"
                            //         : intervalType
                            // }
                            tickLine={false}
                            axisLine={false}
                            minTickGap={5}
                        />
                        <YAxis
                            width={yAxisWidth}
                            hide={!showYAxis}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            domain={yAxisDomain as AxisDomain}
                            tick={{ transform: "translate(-3, 0)" }}
                            fill=""
                            stroke=""
                            className={classMerge(
                                // common
                                "text-sm",
                                // light
                                "fill-tremor-content",
                                // dark
                                "dark:fill-dark-tremor-content"
                            )}
                            tickFormatter={valueFormatter}
                            allowDecimals={allowDecimals}
                        />
                        {/* <Tooltip
                                wrapperStyle={{ outline: "none" }}
                                isAnimationActive={false}
                                cursor={{ stroke: "#d1d5db", strokeWidth: 1 }}
                                content={
                                    showTooltip ? (
                                        ({ active, payload, label }) =>
                                            CustomTooltip ? (
                                                <CustomTooltip
                                                    payload={payload?.map(
                                                        (payloadItem: any) => ({
                                                            ...payloadItem,
                                                            color:
                                                                categoryColors.get(
                                                                    payloadItem.dataKey
                                                                ) ??
                                                                BaseColors.Gray,
                                                        })
                                                    )}
                                                    active={active}
                                                    label={label}
                                                />
                                            ) : (
                                                <ChartTooltip
                                                    active={active}
                                                    payload={payload}
                                                    label={label}
                                                    valueFormatter={
                                                        valueFormatter
                                                    }
                                                    categoryColors={
                                                        categoryColors
                                                    }
                                                />
                                            )
                                    ) : (
                                        <></>
                                    )
                                }
                                position={{ y: 0 }}
                            /> */}
                        {/* {showLegend ? (
                                <Legend
                                    verticalAlign="top"
                                    height={legendHeight}
                                    content={({ payload }) =>
                                        ChartLegend(
                                            { payload },
                                            categoryColors,
                                            setLegendHeight,
                                            activeLegend,
                                            hasOnValueChange
                                                ? (clickedLegendItem: string) =>
                                                      onCategoryClick(
                                                          clickedLegendItem
                                                      )
                                                : undefined
                                        )
                                    }
                                />
                            ) : null} */}
                        {categories.map((category) => {
                            return (
                                <defs key={category}>
                                    {showGradient ? (
                                        <linearGradient
                                            className="bg-red-400 text-blue-500"
                                            // id={categoryColors.get(
                                            //     category
                                            // )}
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                offset="5%"
                                                stopColor="currentColor"
                                                stopOpacity={
                                                    activeDot ||
                                                    (activeLegend &&
                                                        activeLegend !==
                                                            category)
                                                        ? 0.15
                                                        : 0.4
                                                }
                                            />
                                            <stop
                                                offset="95%"
                                                stopColor="currentColor"
                                                stopOpacity={0}
                                            />
                                        </linearGradient>
                                    ) : (
                                        <linearGradient
                                            className="bg-red-300"
                                            // id={categoryColors.get(
                                            //     category
                                            // )}
                                            x1="0"
                                            y1="0"
                                            x2="0"
                                            y2="1"
                                        >
                                            <stop
                                                stopColor="currentColor"
                                                stopOpacity={
                                                    activeDot ||
                                                    (activeLegend &&
                                                        activeLegend !==
                                                            category)
                                                        ? 0.1
                                                        : 0.3
                                                }
                                            />
                                        </linearGradient>
                                    )}
                                </defs>
                            );
                        })}
                        {categories.map((category) => (
                            <Area
                                className="stroke-slate-400"
                                strokeOpacity={
                                    activeDot ||
                                    (activeLegend && activeLegend !== category)
                                        ? 0.3
                                        : 1
                                }
                                activeDot={(props: any) => {
                                    const {
                                        cx,
                                        cy,
                                        stroke,
                                        strokeLinecap,
                                        strokeLinejoin,
                                        strokeWidth,
                                        dataKey,
                                    } = props;
                                    return (
                                        <Dot
                                            className={classMerge(
                                                "stroke-tremor-background dark:stroke-dark-tremor-background",
                                                onValueChange
                                                    ? "cursor-pointer"
                                                    : "",
                                                "fill-cyan-400"
                                            )}
                                            cx={cx}
                                            cy={cy}
                                            r={5}
                                            fill=""
                                            stroke={stroke}
                                            strokeLinecap={strokeLinecap}
                                            strokeLinejoin={strokeLinejoin}
                                            strokeWidth={strokeWidth}
                                            onClick={(dotProps: any, event) =>
                                                onDotClick(props, event)
                                            }
                                        />
                                    );
                                }}
                                dot={(props: any) => {
                                    const {
                                        stroke,
                                        strokeLinecap,
                                        strokeLinejoin,
                                        strokeWidth,
                                        cx,
                                        cy,
                                        dataKey,
                                        index,
                                    } = props;

                                    if (
                                        (hasOnlyOneValueForThisKey(
                                            data,
                                            category
                                        ) &&
                                            !(
                                                activeDot ||
                                                (activeLegend &&
                                                    activeLegend !== category)
                                            )) ||
                                        (activeDot?.index === index &&
                                            activeDot?.dataKey === category)
                                    ) {
                                        return (
                                            <Dot
                                                key={index}
                                                cx={cx}
                                                cy={cy}
                                                r={5}
                                                stroke={stroke}
                                                fill=""
                                                strokeLinecap={strokeLinecap}
                                                strokeLinejoin={strokeLinejoin}
                                                strokeWidth={strokeWidth}
                                                className={classMerge(
                                                    "stroke-red-800 dark:stroke-red-900",
                                                    onValueChange
                                                        ? "cursor-pointer"
                                                        : "",
                                                    "fill-purple-400"
                                                )}
                                            />
                                        );
                                    }
                                    return <Fragment key={index}></Fragment>;
                                }}
                                key={category}
                                name={category}
                                type={curveType}
                                dataKey={category}
                                stroke="#8884d8"
                                fill={`#8884d8`}
                                strokeWidth={2}
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                isAnimationActive={showAnimation}
                                animationDuration={animationDuration}
                                stackId={stack ? "a" : undefined}
                                connectNulls={connectNulls}
                            />
                        ))}
                        {onValueChange
                            ? categories.map((category) => (
                                  <Line
                                      className={classMerge("cursor-pointer")}
                                      strokeOpacity={0}
                                      key={category}
                                      name={category}
                                      type={curveType}
                                      dataKey={category}
                                      stroke="transparent"
                                      fill="transparent"
                                      legendType="none"
                                      tooltipType="none"
                                      strokeWidth={12}
                                      connectNulls={connectNulls}
                                      onClick={(props: any, event) => {
                                          event.stopPropagation();
                                          const { name } = props;
                                          onCategoryClick(name);
                                      }}
                                  />
                              ))
                            : null}
                    </ReChartsAreaChart>
                ) : (
                    <div>noData</div>
                )}
            </ResponsiveContainer>
        </div>
    );
});

AreaChart.displayName = "AreaChart";
