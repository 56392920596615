import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    useGetAuthenticatedSessionUser,
    useHasAuthenticatedSession,
    useLogin,
    useLogout,
    useVerifyOneTimeLoginToken,
} from "../hooks";
import { IUnauthenticatedUser } from "../types";
import { AuthContext, IAuthContext } from "./AuthContext";

/**
 * AuthLoaderProvider
 * Decorates AuthContext.Provider with loader functionality
 * @param props
 * @returns
 */
export function AuthProvider(props: any) {
    const navigate = useNavigate();
    const location = useLocation();
    const [unauthenticatedUser, setUnauthenticatedUser] =
        useState<IUnauthenticatedUser>();
    const [isInitialized, setIsInitialized] = useState(false);
    const loginMutator = useLogin();
    const {
        data: hasAuthenticatedSession,
        isLoading: isHasAuthenticatedSessionLoading,
    } = useHasAuthenticatedSession();
    const logoutMutator = useLogout();
    const { data: authenticatedUser = undefined } =
        useGetAuthenticatedSessionUser({
            enabled: !!hasAuthenticatedSession,
        });
    const verifyOneTimeLoginTokenMutator = useVerifyOneTimeLoginToken();
    const login = useCallback(loginMutator.mutateAsync, []);
    const logout = useCallback(logoutMutator.mutateAsync, []);
    const verifyOneTimeLoginToken = useCallback(
        verifyOneTimeLoginTokenMutator.mutateAsync,
        []
    );

    useEffect(() => {
        if (!isInitialized && !isHasAuthenticatedSessionLoading) {
            if (
                !hasAuthenticatedSession ||
                (hasAuthenticatedSession && authenticatedUser)
            ) {
                setIsInitialized(true);
            }
        }
    }, [hasAuthenticatedSession, authenticatedUser]);

    const nextAuthContext = useMemo<IAuthContext>(
        () => ({
            login,
            logout,
            setUnauthenticatedUser,
            unauthenticatedUser,
            authenticatedUser,
            isInitialized,
            verifyOneTimeLoginToken,
            hasAuthenticatedSession,
        }),
        [
            authenticatedUser,
            setUnauthenticatedUser,
            login,
            logout,
            unauthenticatedUser,
            loginMutator.mutateAsync,
            logoutMutator.mutateAsync,
            verifyOneTimeLoginToken,
            hasAuthenticatedSession,
            isInitialized,
        ]
    );

    useEffect(() => {
        const hasAuthenticatedUser = !!authenticatedUser;
        const hasUnauthenticatedUser = !!unauthenticatedUser;

        if (hasUnauthenticatedUser && !hasAuthenticatedUser) {
            // when in sign-in pending token verification mode...
            // navigate(`/auth/verify`);
        } else if (
            !hasUnauthenticatedUser &&
            !hasAuthenticatedUser &&
            !hasAuthenticatedUser
        ) {
            // when guest
            // navigate(`/auth/sign-in`);
        } else if (hasAuthenticatedUser) {
            // when authenticated
            // navigate(`/`);
        }
    }, [unauthenticatedUser, authenticatedUser, hasAuthenticatedSession]);

    return <AuthContext.Provider value={nextAuthContext} {...props} />;
}
