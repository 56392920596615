import React from "react";
import { classMerge } from "../../utils";

export const LogoWorck = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        viewBox="0 0 92 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={classMerge("inline-block", props.className)}
    >
        <path d="M47.0965 20V17.9308H55.8712V20H47.0965Z" fill="#672A61" />
        <path
            d="M4.35455 2.05225L6.16633 9.65395L8.31569 2.05225H11.4802L13.6296 9.71223L15.4802 2.05225H18.9022L15.4802 15.8203H12.3375L9.87003 7.9806L7.32237 15.8203H4.19911L0.876709 2.05225H4.35941H4.35455Z"
            fill="#672A61"
        />
        <path
            d="M31.7279 8.95691C31.7279 13.6321 29.0613 16.0802 25.3211 16.0802C21.581 16.0802 18.9532 13.6321 18.9532 8.95691C18.9532 4.28175 21.6004 1.7948 25.3211 1.7948C29.0418 1.7948 31.7279 4.26232 31.7279 8.95691ZM22.2562 8.95691C22.2562 11.6819 23.4292 13.0346 25.3211 13.0346C27.2131 13.0346 28.425 11.6819 28.425 8.95691C28.425 6.23196 27.2106 4.85734 25.3211 4.85734C23.4316 4.85734 22.2562 6.22953 22.2562 8.95691Z"
            fill="#672A61"
        />
        <path
            d="M39.235 2.05225C42.2781 2.05225 44.3278 3.52401 44.3278 6.45055C44.3278 8.45905 43.3734 9.77295 41.7802 10.3898L45.0224 15.8227H41.2823L38.4578 10.8294H36.7067V15.8227H33.4037V2.05225H39.2325H39.235ZM36.6873 8.02189H39.2932C40.408 8.02189 41.1827 7.5653 41.1827 6.50883C41.1827 5.45237 40.4056 5.03707 39.2932 5.03707H36.6873V8.02189Z"
            fill="#672A61"
        />
        <path
            d="M54.8415 6.62782C54.1251 5.53249 53.3285 4.85732 51.8761 4.85732C49.8263 4.85732 48.6533 6.21009 48.6533 8.9569C48.6533 11.7037 49.8263 13.0346 51.8761 13.0346C53.19 13.0346 53.9842 12.4979 54.7614 11.422L57.19 13.1925C55.9563 15.1427 54.1663 16.0777 51.8785 16.0777C48.1384 16.0777 45.3333 13.6296 45.3333 8.93504C45.3333 4.24045 48.119 1.79236 51.9004 1.79236C54.6059 1.79236 56.3181 3.06498 57.2532 4.8379L54.8464 6.62782H54.8415Z"
            fill="#672A61"
        />
        <path
            d="M69.4596 2.05225L65.5009 7.08685L70.2562 15.8227H66.5962L63.3928 9.77295L61.7219 11.9029V15.8227H58.4189V2.05225H61.7219V7.44387L65.8797 2.05225H69.462H69.4596Z"
            fill="#672A61"
        />
        <path
            d="M76.7626 4.66059L78.0376 6.23679L78.7079 3.54584L82.1712 2.96296L83.9708 5.01518L84.969 1.53005L86.6399 1.25805C85.2993 0.459017 83.7353 0 82.0643 0C78.4821 0 75.3952 2.10565 73.9623 5.1439L76.765 4.66059H76.7626Z"
            fill="#672A61"
        />
        <path
            d="M87.7304 13.8094L84.204 14.6448L82.2902 12.2623L81.598 14.6594L78.2635 15.4366L73.1196 8.54162C73.1123 8.68005 73.1099 8.81848 73.1099 8.95692C73.1099 13.9041 77.1196 17.9138 82.0668 17.9138C87.0139 17.9138 91.0236 13.9041 91.0236 8.95692C91.0236 7.80816 90.8051 6.71041 90.4116 5.70251L87.7352 13.8118L87.7304 13.8094Z"
            fill="#672A61"
        />
        <path
            d="M84.9666 1.53007L87.4122 4.19673L84.2015 14.6448L87.7304 13.8094L91.1232 3.52642L88.6363 0.932617L84.9666 1.53007Z"
            fill="#41B6E6"
        />
        <path
            d="M83.9708 5.01523L82.1712 2.96301L78.7079 3.54346L82.9435 8.60236L83.9708 5.01523Z"
            fill="#41B6E6"
        />
        <path
            d="M79.9004 9.29211L82.2878 12.2624L81.5956 14.6594L78.2587 15.4366L79.9004 9.29211Z"
            fill="#41B6E6"
        />
        <path
            d="M77.1221 9.91869L78.0377 6.23684L76.7626 4.66064L73.4912 5.22409L77.1221 9.91869Z"
            fill="#41B6E6"
        />
        <path
            d="M87.4122 4.1967L84.2015 14.6448L79.9004 9.29202L78.2586 15.4365L72.8475 8.18213L73.4911 5.22402L77.122 9.91862L78.7079 3.54339L82.9435 8.60228L84.9665 1.53003L87.4122 4.1967Z"
            fill="white"
        />
    </svg>
);
