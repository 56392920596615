"use client";

import { DialogProps } from "@radix-ui/react-dialog";
import { Command as CommandPrimitive } from "cmdk";
import { Search } from "lucide-react";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";
import { Dialog, DialogContent } from "../ui/dialog";

export type TCommandProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive
>;

const Command: FC<TCommandProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive>,
    TCommandProps
>(({ className, ...props }, ref) => (
    <CommandPrimitive
        ref={ref}
        className={classMerge(
            "flex h-full w-full flex-col overflow-hidden rounded-md bg-popover text-popover-foreground",
            className
        )}
        {...props}
    />
));
Command.displayName = CommandPrimitive.displayName;

export interface ICommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: ICommandDialogProps) => {
    return (
        <Dialog {...props}>
            <DialogContent className="overflow-hidden p-0 shadow-lg">
                <Command className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
                    {children}
                </Command>
            </DialogContent>
        </Dialog>
    );
};

export type TCommandInputProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.Input
>;

const CommandInput: FC<TCommandInputProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Input>,
    TCommandInputProps
>(({ className, ...props }, ref) => (
    <div className="flex items-center border-b px-3" cmdk-input-wrapper="">
        <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
        <CommandPrimitive.Input
            ref={ref}
            className={classMerge(
                "flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            {...props}
        />
    </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

export type TCommandListProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.List
>;

const CommandList: FC<TCommandListProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.List>,
    TCommandListProps
>(({ className, ...props }, ref) => (
    <CommandPrimitive.List
        ref={ref}
        className={classMerge(
            "max-h-[300px] overflow-y-auto overflow-x-hidden",
            className
        )}
        {...props}
    />
));

CommandList.displayName = CommandPrimitive.List.displayName;

export type TCommandEmptyProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.Empty
>;

const CommandEmpty: FC<TCommandEmptyProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Empty>,
    TCommandEmptyProps
>((props, ref) => (
    <CommandPrimitive.Empty
        ref={ref}
        className="py-6 text-center text-sm"
        {...props}
    />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

export type TCommandGroupProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.Group
>;

const CommandGroup: FC<TCommandGroupProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Group>,
    TCommandGroupProps
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Group
        ref={ref}
        className={classMerge(
            "overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground",
            className
        )}
        {...props}
    />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

export type TCommandSeparatorProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.Separator
>;

const CommandSeparator: FC<TCommandSeparatorProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Separator>,
    TCommandSeparatorProps
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Separator
        ref={ref}
        className={classMerge("-mx-1 h-px bg-border", className)}
        {...props}
    />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

export type TCommandItemProps = React.ComponentPropsWithoutRef<
    typeof CommandPrimitive.Item
>;

const CommandItem: FC<TCommandItemProps> = React.forwardRef<
    React.ElementRef<typeof CommandPrimitive.Item>,
    TCommandItemProps
>(({ className, ...props }, ref) => (
    <CommandPrimitive.Item
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        {...props}
    />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            className={classMerge(
                "ml-auto text-xs tracking-widest text-muted-foreground",
                className
            )}
            {...props}
        />
    );
};
CommandShortcut.displayName = "CommandShortcut";

export {
    Command,
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
    CommandShortcut,
};
