import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";
import * as React from "react";
import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TNavigationMenuProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.Root
>;

const NavigationMenu: FC<TNavigationMenuProps> = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Root>,
    TNavigationMenuProps
>(({ className, children, ...props }, ref) => (
    <NavigationMenuPrimitive.Root
        ref={ref}
        className={classMerge(
            "relative z-10 flex max-w-max flex-1 items-center justify-center",
            className
        )}
        {...props}
    >
        {children}
        <NavigationMenuViewport />
    </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

export type TNavigationMenuListProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.List
>;

const NavigationMenuList: FC<TNavigationMenuListProps> = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.List>,
    TNavigationMenuListProps
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.List
        ref={ref}
        className={classMerge(
            "group flex flex-1 list-none items-center justify-center space-x-1",
            className
        )}
        {...props}
    />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

const navigationMenuTriggerStyle = cva(
    "group inline-flex h-10 w-max items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50"
);

export type TNavigationMenuTriggerProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.Trigger
>;

const NavigationMenuTrigger: FC<TNavigationMenuTriggerProps> = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
    TNavigationMenuTriggerProps
>(({ className, children, ...props }, ref) => (
    <NavigationMenuPrimitive.Trigger
        ref={ref}
        className={classMerge(navigationMenuTriggerStyle(), "group", className)}
        {...props}
    >
        {children}{" "}
        <ChevronDown
            className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]:rotate-180"
            aria-hidden="true"
        />
    </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

export type TNavigationMenuContextProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.Content
>;

const NavigationMenuContent: FC<TNavigationMenuContextProps> = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Content>,
    TNavigationMenuContextProps
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.Content
        ref={ref}
        className={classMerge(
            "left-0 top-0 w-full data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto ",
            className
        )}
        {...props}
    />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = NavigationMenuPrimitive.Link;

export type TNavigationMenuViewportProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.Viewport
>;

const NavigationMenuViewport: FC<TNavigationMenuViewportProps> =
    React.forwardRef<
        React.ElementRef<typeof NavigationMenuPrimitive.Viewport>,
        TNavigationMenuViewportProps
    >(({ className, ...props }, ref) => (
        <div
            className={classMerge(
                "absolute left-0 top-full flex justify-center"
            )}
        >
            <NavigationMenuPrimitive.Viewport
                className={classMerge(
                    "origin-top-center relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)]",
                    className
                )}
                ref={ref}
                {...props}
            />
        </div>
    ));
NavigationMenuViewport.displayName =
    NavigationMenuPrimitive.Viewport.displayName;

export type TNavigationMenuIndicatorProps = React.ComponentPropsWithoutRef<
    typeof NavigationMenuPrimitive.Indicator
>;

const NavigationMenuIndicator: FC<TNavigationMenuIndicatorProps> =
    React.forwardRef<
        React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
        TNavigationMenuIndicatorProps
    >(({ className, ...props }, ref) => (
        <NavigationMenuPrimitive.Indicator
            ref={ref}
            className={classMerge(
                "top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
                className
            )}
            {...props}
        >
            <div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm bg-border shadow-md" />
        </NavigationMenuPrimitive.Indicator>
    ));
NavigationMenuIndicator.displayName =
    NavigationMenuPrimitive.Indicator.displayName;

export {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuIndicator,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    NavigationMenuViewport,
    navigationMenuTriggerStyle,
};
