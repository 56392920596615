import { validationUtils } from "@app/app";
import {
    Button,
    Flex,
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    Input,
    LogoWorck,
    RadioGroup,
    RadioGroupItem,
    Switch,
    Textarea,
    Tooltip,
    TooltipArrow,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@worck/ui";
import { classMerge } from "@worck/ui/src/utils";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { TipRewardBadge } from "../components/TipRewardBadge";
import { TipSection } from "../components/TipSection";
import { TipSectionHeading } from "../components/TipSectionHeading";
import { useCreateVacancyTip, useGetTipsterVacancy } from "../hooks";
import { ITipsterLeadTip } from "../types";

export const TipLeadItemPage = () => {
    const [isToopTipOpen, setIsToopTipOpen] = useState<string | null>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();
    const { tipsterVacancyShortCode } = useParams();
    const { mutateAsync: createVacancyTipMutator } = useCreateVacancyTip(
        tipsterVacancyShortCode || ""
    );
    const { data: tipsterVacancy } = useGetTipsterVacancy(
        tipsterVacancyShortCode || "",
        {
            enabled: !!tipsterVacancyShortCode,
        }
    );

    const [showTipCanBeSharedField, setShowTipCanBeSharedField] =
        useState(true);
    const [percentageBar, setPercentageBar] = useState<number>(0);
    const [state, setState] = useState(1);
    const [formStep, setFormStep] = useState(1);
    const { watch, ...form } = useForm({
        defaultValues: {
            tipster_full_name: "",
            tipster_email: "",
            tipster_phone_number: "",
            data: {
                lead_email: "",
                tip_can_be_shared: "",
                approached: "",
                how_do_you_know_the_candidate: "",
                candidate_job_openness: "",
                lead_phone_number: "",
                lead_linkedin: "",
                lead_why_suitable: "",
                lead_extra_info: "",
            },
        },
        resolver:
            validationUtils.createValidationEngineResolver<ITipsterLeadTip>({
                tipster_vacancy_id: "required|string|minLength:2|maxLength:255",
                tipster_full_name: "required|string|minLength:2|maxLength:255",
                tipster_email:
                    "required|string|email|minLength:2|maxLength:255",
                "data.lead_email": "email|minLength:2|maxLength:255",
                "data.tip_can_be_shared": "required|string",
                "data.approached": "required|string",
                "data.how_do_you_know_the_candidate": "required|string",
                "data.candidate_job_openness": "required|string",
                "data.lead_phone_number": "string|minLength:10|maxLength:255",
                "data.lead_linkedin":
                    "required|string|minLength:15|maxLength:255",
                "data.lead_why_suitable": "required|string",
                "data.lead_extra_info": "string",
            }),
    });

    const onSubmit = async (values: ITipsterLeadTip) => {
        try {
            await createVacancyTipMutator(values);

            navigate("/tip/thank-you");
        } catch (error) {
            validationUtils.forEachValidationErrorResolvedFromError<ITipsterLeadTip>(
                error,
                values,
                form.setError
            );
        }
    };

    useEffect(() => {
        const values = watch();

        const filledValuesCount =
            Object.values(values).filter((value) => !!value).length || 0;
        setFormStep(111111);

        setPercentageBar(30);
    }, [watch()]);

    const minimumBonus = tipsterVacancy?.vacancy_bonus_amount || 1 * 0.8;
    const maximumBonus = tipsterVacancy?.vacancy_bonus_amount || 1;

    useEffect(() => {
        if (watch("data.approached") === "yes") {
            setShowTipCanBeSharedField(false);
            form.setValue("data.tip_can_be_shared", "yes");
        } else if (watch("data.approached") === "no") {
            setShowTipCanBeSharedField(true);
        }
    }, [watch("data.approached")]);

    useEffect(() => {
        if (watch("data.recruiter_can_contact_me") === true) {
            form.setValue("data.tip_can_be_shared", "yes");
        }
    }, [watch("data.recruiter_can_contact_me")]);

    return (
        <div className="flex justify-between flex-col h-full">
            <div className="w-full text-center my-12 px-4">
                <LogoWorck className="w-48" />
            </div>

            {state === 0 && (
                <Flex className="align-center">
                    <div className="max-w-xl mx-auto flex justify-start flex-col gap-y-6">
                        <p className="font-light text-xl leading-7">
                            Beste {tipsterVacancy?.name}, <br />
                            <br />
                            Ken jij misschien iemand die past bij de functie van
                            {tipsterVacancy?.vacancy_title} voor Senior
                            Developer voor Work in Groningen?
                        </p>
                        <p>
                            Tip ons! Wie zouden we volgens jou kunnen benaderen?
                            Via worck.com belonen we jou voor de gouden tip!
                        </p>
                        <p className="font-bold">
                            Ontvang tussen de € {minimumBonus} en €{" "}
                            {maximumBonus} cash als jouw kandidaat het wordt.
                            PS: jij blijft altijd anoniem!
                        </p>

                        <Button
                            onClick={() => setState(2)}
                            className="gap-x-2 my-8"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                color="#fff"
                                fill="none"
                            >
                                <path
                                    opacity="0.4"
                                    d="M5.53327 17C4.11143 19.0062 3.75309 20.0652 4.15321 20.9156C4.19286 20.9999 4.23928 21.0812 4.29207 21.1589C4.86372 22 6.34111 22 9.2959 22H14.7041C17.6589 22 19.1363 22 19.7079 21.1589C19.7607 21.0812 19.8071 20.9999 19.8468 20.9156C20.2469 20.0652 19.8891 19.0062 18.4673 17"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12.9981 7H11.0019C8.13196 7 6.19701 10.0691 7.32753 12.828C7.48501 13.2124 7.84633 13.4615 8.24612 13.4615H8.9491C9.18605 13.4615 9.39259 13.6302 9.45006 13.8706L10.3551 17.6567C10.5438 18.4462 11.222 19 12 19C12.778 19 13.4562 18.4462 13.6449 17.6567L14.5499 13.8706C14.6074 13.6302 14.814 13.4615 15.0509 13.4615H15.7539C16.1537 13.4615 16.515 13.2124 16.6725 12.828C17.803 10.0691 15.868 7 12.9981 7Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                />
                                <path
                                    d="M14.5 4.5C14.5 5.88071 13.3807 7 12 7C10.6193 7 9.5 5.88071 9.5 4.5C9.5 3.11929 10.6193 2 12 2C13.3807 2 14.5 3.11929 14.5 4.5Z"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                />
                            </svg>
                            Ja, ik heb een tip
                        </Button>
                    </div>
                </Flex>
            )}

            <Form {...form} watch={watch}>
                <div className="overflow-scroll grow h-auto px-4 pb-8">
                    <form
                        noValidate
                        id="tip-lead-form"
                        onSubmit={form.handleSubmit(onSubmit)}
                    >
                        {!!tipsterVacancy?.id && (
                            <FormField
                                control={form.control}
                                name="tipster_vacancy_id"
                                defaultValue={String(tipsterVacancy?.id)}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input type="hidden" {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )}

                        <div className="max-w-xl mx-auto flex justify-start flex-col gap-y-6">
                            {!isSubmitted && (
                                <React.Fragment>
                                    <TipSection>
                                        <div
                                        // className={classMerge(
                                        //     state === 1 && "flex flex-col gap-y-6",
                                        //     state !== 1 && "hidden"
                                        // )}
                                        >
                                            <TipSectionHeading
                                                title="Jouw gegegevens"
                                                description="Jouw privacy staat bij ons centraal. We willen
                                alleen weten hoe we jou kunnen bereiken en jouw
                                beloning kunnen uitkeren."
                                            />

                                            <FormField
                                                control={form.control}
                                                name="tipster_full_name"
                                                render={({ field }) => (
                                                    <FormItem
                                                        className={classMerge(
                                                            "transform transition-opacity duration-300",
                                                            formStep > 1 &&
                                                                "opacity-100",
                                                            formStep < 1 &&
                                                                "opacity-0"
                                                        )}
                                                    >
                                                        <FormLabel>
                                                            Je naam (of alias)
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                placeholder="Jan Jansen"
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormDescription></FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                            <FormField
                                                control={form.control}
                                                name="tipster_email"
                                                render={({ field }) => (
                                                    <FormItem
                                                        className={classMerge(
                                                            "transform transition-opacity duration-300",
                                                            formStep > 2 &&
                                                                "opacity-100",
                                                            formStep < 2 &&
                                                                "opacity-0"
                                                        )}
                                                    >
                                                        <FormLabel>
                                                            Je e-mail adres
                                                        </FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                placeholder="jan.jansen@worck.com"
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormDescription></FormDescription>
                                                        <FormMessage />
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </TipSection>

                                    <TipSection>
                                        <TipSectionHeading
                                            title="Jouw relatie tot de Kandidaat"
                                            description="We willen graag weten hoe je de kandidaat kent en of je al met hem/haar over de vacature hebt gesproken."
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.approached"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "transform transition-opacity duration-300",
                                                        formStep > 3 &&
                                                            "opacity-100",
                                                        formStep < 3 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel className="flex items-center">
                                                        Heb je de kandidaat over
                                                        de functie gesproken?
                                                        <TooltipProvider>
                                                            <Tooltip
                                                                open={
                                                                    isToopTipOpen ===
                                                                    "gesproken"
                                                                }
                                                                onOpenChange={() =>
                                                                    setIsToopTipOpen(
                                                                        null
                                                                    )
                                                                }
                                                            >
                                                                <TooltipTrigger
                                                                    asChild
                                                                    className="cursor-pointer ml-2.5"
                                                                    onMouseEnter={() =>
                                                                        setIsToopTipOpen(
                                                                            "gesproken"
                                                                        )
                                                                    }
                                                                    onClick={() =>
                                                                        setIsToopTipOpen(
                                                                            "gesproken"
                                                                        )
                                                                    }
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                        color={
                                                                            "#65295f"
                                                                        }
                                                                        fill={
                                                                            "none"
                                                                        }
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            clipRule="evenodd"
                                                                            d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                            fill="currentColor"
                                                                        />
                                                                    </svg>
                                                                </TooltipTrigger>

                                                                {/* <TooltipContent className="text-muted-foreground w-96">
                                                                    <TooltipArrow className="fill-white" />
                                                                    <p className="leading-5 font-normal text-sm">
                                                                        Lorem
                                                                        ipsum
                                                                        dolor
                                                                        sit
                                                                        amet,
                                                                        consectetur
                                                                        adipiscing
                                                                        elit.
                                                                        Proin
                                                                        venenatis
                                                                        nisi et
                                                                        odio
                                                                        imperdiet
                                                                        tincidunt.
                                                                    </p>
                                                                </TooltipContent> */}
                                                            </Tooltip>
                                                        </TooltipProvider>
                                                    </FormLabel>
                                                    <FormControl>
                                                        <RadioGroup
                                                            onValueChange={
                                                                field.onChange
                                                            }
                                                            defaultValue={
                                                                field.value
                                                            }
                                                            className="grid grid-cols-1 gap-2"
                                                        >
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="yes" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex justify-between w-full">
                                                                    <p>
                                                                        Ja
                                                                        zeker!
                                                                    </p>
                                                                    <TipRewardBadge>
                                                                        20%
                                                                        beloning
                                                                    </TipRewardBadge>
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="no" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Nee, ik geef
                                                                    alleen een
                                                                    tip
                                                                </FormLabel>
                                                            </FormItem>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.tip_can_be_shared"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "mb-4 space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 4 &&
                                                            "opacity-100",
                                                        formStep < 4 &&
                                                            "opacity-0",
                                                        !showTipCanBeSharedField &&
                                                            "hidden"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Mag de kandidaat weten
                                                        wij jij bent?
                                                    </FormLabel>
                                                    <FormControl>
                                                        <RadioGroup
                                                            onValueChange={
                                                                field.onChange
                                                            }
                                                            defaultValue={
                                                                field.value
                                                            }
                                                            className="grid grid-cols-2 gap-4"
                                                        >
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="no" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Nee, ik
                                                                    blijf liever
                                                                    anoniem
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem
                                                                        checked={
                                                                            field.value ===
                                                                            "yes"
                                                                        }
                                                                        value="yes"
                                                                    />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Ja, geen
                                                                    probleem
                                                                </FormLabel>
                                                            </FormItem>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormDescription className="font-bold">
                                                        {field.value === "no"
                                                            ? "Jouw gegevens worden nooit gedeeld met de kandidaat."
                                                            : "Jouw naam kan worden gedeeld met de kandidaat"}
                                                    </FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />

                                        <FormField
                                            control={form.control}
                                            name="data.how_do_you_know_the_candidate"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300 relative",
                                                        formStep > 5 &&
                                                            "opacity-100",
                                                        formStep < 5 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Wat is jouw relatie tot
                                                        de kandidaat?
                                                    </FormLabel>
                                                    <FormControl>
                                                        <RadioGroup
                                                            onValueChange={
                                                                field.onChange
                                                            }
                                                            defaultValue={
                                                                field.value
                                                            }
                                                            className="grid grid-cols-1 gap-2"
                                                        >
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="colleague" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex flex-between">
                                                                    Directe
                                                                    (oud)
                                                                    collega{" "}
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger
                                                                                asChild
                                                                                className="ml-2.5 cursor-pointer"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 24 24"
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    color={
                                                                                        "#65295f"
                                                                                    }
                                                                                    fill={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="text-muted-foreground w-96">
                                                                                <TooltipArrow className="fill-white" />
                                                                                <p className="leading-5 font-normal text-sm">
                                                                                    Iemand
                                                                                    waarmee
                                                                                    je
                                                                                    direct
                                                                                    en
                                                                                    regelmatig
                                                                                    mee
                                                                                    samen
                                                                                    heb
                                                                                    gewerkt.
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="business" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex">
                                                                    Mee
                                                                    samengewerkt
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger
                                                                                asChild
                                                                                className="cursor-pointer ml-2.5"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 24 24"
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    color={
                                                                                        "#65295f"
                                                                                    }
                                                                                    fill={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="text-muted-foreground w-96">
                                                                                <TooltipArrow className="fill-white" />
                                                                                <p className="leading-5 font-normal text-sm">
                                                                                    Iemand
                                                                                    waarmee
                                                                                    je
                                                                                    hebt
                                                                                    samengewerkt
                                                                                    in
                                                                                    (bijv.)
                                                                                    een
                                                                                    leveranciersrelatie
                                                                                    of
                                                                                    aan
                                                                                    een
                                                                                    gezamelijk
                                                                                    project.
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="know_peronsally" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex">
                                                                    Bekende uit
                                                                    persoonlijke
                                                                    kring
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger
                                                                                asChild
                                                                                className="cursor-pointer ml-2.5"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 24 24"
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    color={
                                                                                        "#65295f"
                                                                                    }
                                                                                    fill={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="text-muted-foreground w-96">
                                                                                <TooltipArrow className="fill-white" />
                                                                                <p className="leading-5 font-normal text-sm">
                                                                                    Een
                                                                                    bekende
                                                                                    uit
                                                                                    privékring;
                                                                                    vrienden
                                                                                    /
                                                                                    kennissen
                                                                                    of
                                                                                    familie.
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="indirect" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex">
                                                                    Uit indirect
                                                                    netwerk
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger
                                                                                asChild
                                                                                className="cursor-pointer ml-2.5"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 24 24"
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    color={
                                                                                        "#65295f"
                                                                                    }
                                                                                    fill={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="text-muted-foreground w-96">
                                                                                <TooltipArrow className="fill-white" />
                                                                                <p className="leading-5 font-normal text-sm">
                                                                                    Iemand
                                                                                    uit
                                                                                    jouw
                                                                                    indirecte
                                                                                    netwerk,
                                                                                    wiens
                                                                                    naam
                                                                                    en
                                                                                    repuatie
                                                                                    je
                                                                                    kent.
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="linkedin_social_media" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm flex">
                                                                    Gevonden via
                                                                    Linkedin/Social
                                                                    Media
                                                                    <TooltipProvider>
                                                                        <Tooltip>
                                                                            <TooltipTrigger
                                                                                asChild
                                                                                className="cursor-pointer ml-2.5"
                                                                            >
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 24 24"
                                                                                    width={
                                                                                        20
                                                                                    }
                                                                                    height={
                                                                                        20
                                                                                    }
                                                                                    color={
                                                                                        "#65295f"
                                                                                    }
                                                                                    fill={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <path
                                                                                        fillRule="evenodd"
                                                                                        clipRule="evenodd"
                                                                                        d="M1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12ZM11.6819 11.0273C11.9289 11.0605 12.2707 11.1494 12.5607 11.4394C12.8507 11.7294 12.9396 12.0711 12.9728 12.3182C13.0003 12.5228 13.0001 12.7608 13 12.9606L13 13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13C10.4477 13 10 12.5523 10 12C10 11.4477 10.4477 11 11 11L11.0394 11C11.2393 10.9999 11.4772 10.9997 11.6819 11.0273ZM11.9954 7C11.4456 7 10.9999 7.44772 10.9999 8C10.9999 8.55228 11.4456 9 11.9954 9H12.0044C12.5542 9 12.9999 8.55228 12.9999 8C12.9999 7.44772 12.5542 7 12.0044 7H11.9954Z"
                                                                                        fill="currentColor"
                                                                                    />
                                                                                </svg>
                                                                            </TooltipTrigger>
                                                                            <TooltipContent className="text-muted-foreground w-96">
                                                                                <TooltipArrow className="fill-white" />
                                                                                <p className="leading-5 font-normal text-sm">
                                                                                    Iemand
                                                                                    wiensprofiel
                                                                                    je
                                                                                    gevonden
                                                                                    hebt
                                                                                    op
                                                                                    Linkedin.
                                                                                </p>
                                                                            </TooltipContent>
                                                                        </Tooltip>
                                                                    </TooltipProvider>
                                                                </FormLabel>
                                                            </FormItem>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.candidate_job_openness"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300 relative",
                                                        formStep > 6 &&
                                                            "opacity-100",
                                                        formStep < 6 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Hoe groot acht jij de
                                                        kans dat jouw kandidaat
                                                        open staat voor een
                                                        andere baan?
                                                    </FormLabel>
                                                    <FormControl>
                                                        <RadioGroup
                                                            onValueChange={
                                                                field.onChange
                                                            }
                                                            defaultValue={
                                                                field.value
                                                            }
                                                            className="grid grid-cols-1 gap-2"
                                                        >
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="unknown" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Weet ik niet
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="low" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Klein
                                                                    (0-40%)
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="medium" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Gemiddeld
                                                                    (40-70%)
                                                                </FormLabel>
                                                            </FormItem>
                                                            <FormItem className="flex items-center space-x-3 space-y-0 border rounded-md p-2 border-slate-300 bg-white">
                                                                <FormControl>
                                                                    <RadioGroupItem value="high" />
                                                                </FormControl>
                                                                <FormLabel className="font-light text-sm">
                                                                    Groot
                                                                    (70-100%)
                                                                </FormLabel>
                                                            </FormItem>
                                                        </RadioGroup>
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </TipSection>

                                    <TipSection>
                                        <TipSectionHeading
                                            title="Over de kandidaat"
                                            description=""
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.lead_linkedin"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 7 &&
                                                            "opacity-100",
                                                        formStep < 7 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Kandidaat LinkedIn
                                                        profiel URL
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="https://www.linkedin.com/in/jan.jansen/"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.recruiter_can_contact_me"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "mb-3 space-y-3",
                                                        "transform transition-opacity duration-300 flex items-center gap-x-4",
                                                        formStep > 11 &&
                                                            "opacity-100",
                                                        formStep < 11 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel className="mt-4">
                                                        <div className="flex items-center gap-x-4">
                                                            <FormControl>
                                                                <Switch
                                                                    className="bg-white "
                                                                    checked={
                                                                        !!field.value
                                                                    }
                                                                    onCheckedChange={
                                                                        field.onChange
                                                                    }
                                                                />
                                                            </FormControl>

                                                            <div>
                                                                Deze kandidaat
                                                                heeft geen
                                                                LinkedIn
                                                                profiel, neem
                                                                contact met mij
                                                                op over deze
                                                                candidaat.
                                                            </div>
                                                        </div>
                                                    </FormLabel>

                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {/* <FormField
                                            control={form.control}
                                            name="data.lead_email"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 8 &&
                                                            "opacity-100",
                                                        formStep < 8 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Emailadres kandidaat
                                                        (optioneel)
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            type="email"
                                                            placeholder="pietje.puk@gmail.com"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.lead_phone_number"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 9 &&
                                                            "opacity-100",
                                                        formStep < 9 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Telefoonnummer Kandidaat
                                                        (optioneel)
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            placeholder="+310655112215"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription></FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        /> */}

                                        <FormField
                                            control={form.control}
                                            name="data.lead_why_suitable"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "mb-4 space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 10 &&
                                                            "opacity-100",
                                                        formStep < 10 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Waarom denk je dat deze
                                                        kandidaat geschikt is?
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Textarea
                                                            className="bg-white"
                                                            placeholder="Deze kandidaat is..."
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormDescription className="w-full flex justify-start gap-x-4">
                                                        <div>
                                                            Bij een volledige
                                                            canidate motivatie
                                                            ontvang je een extra
                                                            bonus.
                                                        </div>
                                                        <div className="whitespace-nowrap">
                                                            <TipRewardBadge>
                                                                20% beloning
                                                            </TipRewardBadge>
                                                        </div>
                                                    </FormDescription>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="data.lead_extra_info"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 11 &&
                                                            "opacity-100",
                                                        formStep < 11 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Heb je verder nog
                                                        relevante informatie
                                                        (optioneel)
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Textarea
                                                            className="bg-white"
                                                            placeholder="Deze kandidaat is ook..."
                                                            {...field}
                                                        />
                                                    </FormControl>

                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {/* <FormField
                                            control={form.control}
                                            name="data.lead_extra_info"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 11 &&
                                                            "opacity-100",
                                                        formStep < 11 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel>
                                                        Heb je verder nog
                                                        relevante informatie
                                                        (optioneel)
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Textarea
                                                            className="bg-white"
                                                            placeholder="Ik advisseer deze.."
                                                            {...field}
                                                        />
                                                    </FormControl>

                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        /> */}
                                        <FormField
                                            control={form.control}
                                            name="data.recruiter_can_contact_me"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "mb-3 space-y-3",
                                                        "transform transition-opacity duration-300 flex items-center gap-x-4",
                                                        formStep > 11 &&
                                                            "opacity-100",
                                                        formStep < 11 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel className="mt-4">
                                                        <div className="flex items-center gap-x-4">
                                                            <FormControl>
                                                                <Switch
                                                                    className="bg-white "
                                                                    checked={watch(
                                                                        "data.recruiter_can_contact_me"
                                                                    )}
                                                                />
                                                            </FormControl>

                                                            <div>
                                                                Recruiter mag
                                                                contact met mij
                                                                opnemen over
                                                                deze kandidaat.
                                                            </div>
                                                        </div>
                                                    </FormLabel>

                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="tipster_phone_number"
                                            render={({ field }) => (
                                                <FormItem
                                                    className={classMerge(
                                                        "space-y-3",
                                                        "transform transition-opacity duration-300",
                                                        formStep > 11 &&
                                                            "opacity-100",
                                                        formStep < 11 &&
                                                            "opacity-0"
                                                    )}
                                                >
                                                    <FormLabel className="mt-3">
                                                        Mijn telefoonnummer
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            className="bg-white"
                                                            placeholder="+31612345678"
                                                            {...field}
                                                            required={
                                                                !watch(
                                                                    "data.recruiter_can_contact_me"
                                                                )
                                                            }
                                                        />
                                                    </FormControl>

                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    </TipSection>
                                </React.Fragment>
                            )}

                            {!isSubmitted && (
                                <Button
                                    type="submit"
                                    disabled={isSubmitted}
                                    className="mb-5"
                                    form="tip-lead-form"
                                    size="sm"
                                >
                                    Afronden
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width={18}
                                        height={18}
                                        color={"#ffffff"}
                                        fill={"none"}
                                        className="ml-2"
                                    >
                                        <path
                                            d="M9.00005 6L15 12L9 18"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeMiterlimit="16"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Button>
                            )}
                        </div>
                    </form>
                </div>
            </Form>

            {/* <div
                className="flex justify-between items-center w-full px-4 py-2 bg-white"
                style={{
                    boxShadow:
                        "0px -2px 4px 0px rgba(101, 101, 101, 0.06), 0px -4px 8px 0px rgba(101, 101, 101, 0.04)",
                }}
            >
                <div className="max-w-xl min-w-xl w-xl min-w-xl mx-auto flex justify-between items-center item">
                    <div className="flex gap-x-2 items-center w-72">
                        <Progress
                            classNameIndicator="bg-secondary"
                            value={40}
                            className="h-1.5 bg-secondary/10"
                        />
                    </div>
                    <div className="grow w-36" />

                    <Button
                        // className="hidden"
                        onClick={() => setState(() => state + 1)}
                        type="submit"
                        disabled={isSubmitted}
                        form="tip-lead-form"
                        size="sm"
                    >
                        Afronden
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width={18}
                            height={18}
                            color={"#ffffff"}
                            fill={"none"}
                            className="ml-2"
                        >
                            <path
                                d="M9.00005 6L15 12L9 18"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeMiterlimit="16"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </Button>
                </div>
            </div> */}
        </div>
    );
};
