import { utilsQuery } from "@app/common";

const define = utilsQuery.defineQueryKeyCreator("auth");

export const QueryKeys = {
    AuthenticatedUserItem: define("authenticated-user-item"),
    UnauthenticatedUserItem: define("unauthenticated-user-item"),
    HasAuthenticatedSession: define("has-authenticated-session"),
    PasswordReset: define("password-reset"),
} as const;
