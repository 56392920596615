import * as React from "react";
import { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export interface ITitleProps
    extends React.TextareaHTMLAttributes<HTMLHeadingElement> {}

const Title: FC<ITitleProps> = React.forwardRef<
    HTMLTextAreaElement,
    ITitleProps
>(({ className, ...props }, ref) => {
    return <h1 className={classMerge("text-3xl	", className)} {...props} />;
});
Title.displayName = "Title";

export { Title };
