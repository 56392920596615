"use client";

import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TSliderProps = React.ComponentPropsWithoutRef<
    typeof SliderPrimitive.Root
>;

const Slider: FC<TSliderProps> = React.forwardRef<
    React.ElementRef<typeof SliderPrimitive.Root>,
    TSliderProps
>(({ className, ...props }, ref) => (
    <SliderPrimitive.Root
        ref={ref}
        className={classMerge(
            "relative flex w-full touch-none select-none items-center",
            className
        )}
        {...props}
    >
        <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-theme-secondary">
            <SliderPrimitive.Range className="absolute h-full bg-theme-primary" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full border-2 border-theme-primary bg-background ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
));
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
