import { VariantProps } from "class-variance-authority";
import React, { FC } from "react";
import { classMerge } from "../../utils/styleUtils";
import { flexVariants } from "./flexVariants";

export interface IFlexProps
    extends React.HTMLAttributes<HTMLDivElement>,
        VariantProps<typeof flexVariants> {
    children: React.ReactNode;
}

export const Flex: FC<IFlexProps> = React.forwardRef<
    HTMLDivElement,
    IFlexProps
>((props, ref) => {
    const {
        direction,
        justifyContent,
        alignItems,
        children,
        className,
        ...other
    } = props;

    return (
        <div
            ref={ref}
            className={classMerge(
                flexVariants({ direction, justifyContent, alignItems }),
                "flex w-full",
                className
            )}
            {...other}
        >
            {children}
        </div>
    );
});

Flex.displayName = "Flex";
