import { ValueFormatter } from "./types";

export function hasOnlyOneValueForThisKey(array: any[], keyToCheck: string) {
    const val = [];

    for (const obj of array) {
        if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
            val.push(obj[keyToCheck]);
            if (val.length > 1) {
                return false;
            }
        }
    }

    return true;
}
export const getYAxisDomain = (
    autoMinValue: boolean,
    minValue: number | undefined,
    maxValue: number | undefined
) => {
    const minDomain = autoMinValue ? "auto" : minValue ?? 0;
    const maxDomain = maxValue ?? "auto";

    return [minDomain, maxDomain];
};

export const defaultValueFormatter: ValueFormatter = (value: number) =>
    value.toString();
