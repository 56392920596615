import {
    ISelectProps,
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@ui";
import React from "react";
import { IDepartment } from "../types";

const EMPTY_DEPARTMENTS: IDepartment[] = [];

export interface IDepartmentSelectProps extends Omit<ISelectProps, "options"> {
    options?: IDepartment[];
}

export const DepartmentSelect = React.forwardRef(
    (
        {
            options = EMPTY_DEPARTMENTS,
            ...selectFieldProps
        }: IDepartmentSelectProps,
        ref: any
    ) => {
        return (
            <Select {...selectFieldProps}>
                <SelectTrigger>
                    <SelectValue ref={ref} />
                </SelectTrigger>
                <SelectContent>
                    {options.map((option) => (
                        <SelectItem value={option.id} key={option.id}>
                            {option.name}
                        </SelectItem>
                    ))}
                </SelectContent>
            </Select>
        );
    }
);
