"use client";

import * as SeparatorPrimitive from "@radix-ui/react-separator";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TSeparatorProps = React.ComponentPropsWithoutRef<
    typeof SeparatorPrimitive.Root
>;

const Separator: FC<TSeparatorProps> = React.forwardRef<
    React.ElementRef<typeof SeparatorPrimitive.Root>,
    TSeparatorProps
>(
    (
        { className, orientation = "horizontal", decorative = true, ...props },
        ref
    ) => (
        <SeparatorPrimitive.Root
            ref={ref}
            decorative={decorative}
            orientation={orientation}
            className={classMerge(
                "shrink-0 bg-border",
                orientation === "horizontal"
                    ? "h-[1px] w-full"
                    : "h-full w-[1px]",
                className
            )}
            {...props}
        />
    )
);
Separator.displayName = SeparatorPrimitive.Root.displayName;

export { Separator };
