import React from "react";
import { classMerge } from "../../utils";

export const CircleLogoWorck = (props: React.HTMLAttributes<SVGSVGElement>) => (
    <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        className={classMerge("inline-block", props.className)}
    >
        <g id="Group">
            <g id="Group_2">
                <path
                    id="Vector"
                    d="M3.74604 4.66059L5.02109 6.23679L5.6914 3.54584L9.15466 2.96296L10.9543 5.01518L11.9525 1.53005L13.6234 1.25805C12.2828 0.459017 10.7187 0 9.0478 0C5.46553 0 2.37871 2.10565 0.945801 5.1439L3.74847 4.66059H3.74604Z"
                    fill="#672A61"
                />
                <path
                    id="Vector_2"
                    d="M14.7138 13.8094L11.1874 14.6448L9.27359 12.2623L8.58142 14.6594L5.24687 15.4366L0.102978 8.54162C0.0956925 8.68005 0.0932617 8.81848 0.0932617 8.95692C0.0932617 13.9041 4.10298 17.9138 9.05015 17.9138C13.9973 17.9138 18.007 13.9041 18.007 8.95692C18.007 7.80816 17.7885 6.71041 17.395 5.70251L14.7186 13.8118L14.7138 13.8094Z"
                    fill="#672A61"
                />
            </g>
            <path
                id="Vector_3"
                d="M11.9501 1.53007L14.3957 4.19673L11.1851 14.6448L14.7139 13.8094L18.1067 3.52642L15.6198 0.932617L11.9501 1.53007Z"
                fill="#41B6E6"
            />
            <path
                id="Vector_4"
                d="M10.9543 5.01523L9.15466 2.96301L5.69141 3.54346L9.92698 8.60236L10.9543 5.01523Z"
                fill="#41B6E6"
            />
            <path
                id="Vector_5"
                d="M6.88396 9.29211L9.27133 12.2624L8.57916 14.6594L5.24219 15.4366L6.88396 9.29211Z"
                fill="#41B6E6"
            />
            <path
                id="Vector_6"
                d="M4.10546 9.91869L5.02106 6.23684L3.74601 4.66064L0.474609 5.22409L4.10546 9.91869Z"
                fill="#41B6E6"
            />
        </g>
    </svg>
);
