export enum Roles {
    /**
     * A Worck employers with admin permissions.
     */
    Admin = "admin",

    /**
     * A Worck employers with super admin permissions.
     */
    SuperAdmin = "superadmin",

    /**
     * A user that has a developer.
     */
    Developer = "developer",

    /**
     * A user that has a vacancy.
     */
    Employer = "employer",

    /**
     * A user that has candidates to fulfill the vacancy of an employer.
     */
    Recruiter = "recruiter",

    /**
     * A user that publish a vacancy of the employer.
     */
    Publisher = "publisher",

    /**
     * A user that applies to a vacancy of the employer
     */
    Applicant = "applicant",
}
