import * as React from "react";
import { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export interface ITextProps
    extends React.TextareaHTMLAttributes<HTMLParagraphElement> {}

const Text: FC<ITextProps> = React.forwardRef<HTMLParagraphElement, ITextProps>(
    ({ className, ...props }, ref) => {
        return (
            <p
                ref={ref}
                className={classMerge("text-sm", className)}
                {...props}
            />
        );
    }
);
Text.displayName = "Text";

export { Text };
