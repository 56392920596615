import { QueryBuilder, axios, queryBuilder } from "@app/app";
import { departmentTypes } from "@app/department";
import { leadTypes } from "@app/lead";
import { userTypes } from "@app/user";
import { vacancyTypes } from "@app/vacancy";
import { ICompany, IGuestCompany, TPaginatedCompaniesResponse } from "./types";

/**
 * Company
 */

export const getCompanyItem = async (companyId: string): Promise<ICompany> => {
    const response = await axios.get<{ data: ICompany }>(
        `companies/${companyId}`
    );

    return response.data?.data;
};

export const getGuestCompanyItem = async (
    slug: string
): Promise<IGuestCompany> => {
    const response = await axios.get<{ data: IGuestCompany }>(
        `guest/companies/${slug}`
    );

    return response.data?.data;
};

export const getCompanyItems = async (
    page: number,
    search?: string
): Promise<TPaginatedCompaniesResponse> => {
    const response = await axios.get<TPaginatedCompaniesResponse>("companies", {
        params: { page, search },
    });

    return response.data;
};

export const updateCompanyItem = async (item: ICompany): Promise<ICompany> => {
    const response = await axios.post(`companies/${item.id}`, item);

    return response.data;
};

export const createCompanyItem = async (item: ICompany): Promise<ICompany> => {
    const response = await axios.post("companies", item);

    return response.data;
};

export const deleteCompanyItem = async (companyId: string) => {
    const response = await axios.delete(`companies/${companyId}`);

    return response.data;
};

/**
 * CompaniesVacancies
 */

export const getCompanyVacancyItems = async (
    companyId: string,
    page: number,
    search?: string
): Promise<vacancyTypes.TPaginatedVacanciesResponse> => {
    const response = await axios.get<vacancyTypes.TPaginatedVacanciesResponse>(
        `companies/${companyId}/vacancies`,
        {
            params: { page, search },
        }
    );

    return response.data;
};

export const getCompanyVacancyItem = async (
    companyId: string,
    vacancyId: string
): Promise<vacancyTypes.IVacancy> => {
    const response = await axios.get<{ data: vacancyTypes.IVacancy }>(
        `companies/${companyId}/vacancies/${vacancyId}`
    );

    return response.data.data;
};

export const createCompanyVacancyItem = async (
    companyId: string,
    item: vacancyTypes.IVacancy
): Promise<vacancyTypes.IVacancy> => {
    const response = await axios.post(`companies/${companyId}/vacancies`, item);

    return response.data;
};

export const updateCompanyVacancyItem = async (
    companyId: string,
    item: vacancyTypes.IVacancy
): Promise<vacancyTypes.IVacancy> => {
    const response = await axios.post(
        `companies/${companyId}/vacancies/${item.id}`,
        item
    );

    return response.data;
};

/**
 * CompaniesDepartments
 */

export const getCompanyDepartmentItems = async (
    companyId: string,
    page: number,
    search?: string
): Promise<departmentTypes.TPaginatedDepartmentsResponse> => {
    const response =
        await axios.get<departmentTypes.TPaginatedDepartmentsResponse>(
            `companies/${companyId}/departments`,
            {
                params: { page, search },
            }
        );

    return response.data;
};

/**
 * CompanyLeads
 */

export const getCompanyLeads = async (
    companyId: string,
    page: number
): Promise<leadTypes.TPaginatedLeadsResponse> => {
    const response = await axios.get<leadTypes.TPaginatedLeadsResponse>(
        `companies/${companyId}/leads`,
        {
            params: {
                page,
            },
        }
    );

    return response.data;
};

/**
 * CompanyUsers
 */

/**
 * getCompanyUserItems
 * Company user item contains the employment pivot relation
 * @param companyId
 * @returns
 */
export const getCompanyUserItems = async (
    companyId: string,
    query?: QueryBuilder
): Promise<userTypes.IUser[]> => {
    const url = (query || queryBuilder())
        .forModel(`companies/${companyId}/users`)
        .get();

    const response = await axios.get<{ data: userTypes.IUser[] }>(url);

    return response.data?.data;
};

/**
 * getCompanyUserItem
 * Company user item contains the employment pivot relation
 * @param companyId
 * @param userId
 * @returns
 */
export const getCompanyUserItem = async (
    companyId: string,
    userId: string
): Promise<userTypes.IUser> => {
    const response = await axios.get<{ data: userTypes.IUser }>(
        `companies/${companyId}/users/${userId}`
    );

    return response.data?.data;
};

/**
 * getGuestCompanyUserItem
 * Company user item contains the employment pivot relation
 * @param companyId
 * @param userId
 * @returns
 */
export const getGuestCompanyUserItem = async (
    companyId: string,
    userId: string
): Promise<userTypes.IUser> => {
    const response = await axios.get<{ data: userTypes.IUser }>(
        `guest/companies/${companyId}/users/${userId}`
    );

    return response.data?.data;
};
