import { QueryBuilder, queryClient } from "@app/app";
import { commonTypes, utilsQuery } from "@app/common";
import { departmentTypes } from "@app/department";
import { leadTypes } from "@app/lead";
import { userTypes } from "@app/user";
import { vacancyTypes } from "@app/vacancy";
import { useMutation, useQuery } from "react-query";

import {
    createCompanyItem,
    createCompanyVacancyItem,
    getCompanyDepartmentItems,
    getCompanyItem,
    getCompanyItems,
    getCompanyLeads,
    getCompanyUserItem,
    getCompanyUserItems,
    getCompanyVacancyItem,
    getCompanyVacancyItems,
    getGuestCompanyItem,
    getGuestCompanyUserItem,
    updateCompanyItem,
    updateCompanyVacancyItem,
} from "./queries";
import { ICompany, IGuestCompany, TPaginatedCompaniesResponse } from "./types";

const define = utilsQuery.defineQueryKeyCreator("company");

export const QueryKeys = {
    CompanyItems: define("company-items"),
    CompanyItem: define("company-item"),
    GuestCompanyItem: define("guest-company-item"),
    CompanyVacancyItem: define("company-vacancy-item"),
    CompanyVacancyItems: define("company-vacancy-items"),
    CompanyDepartmentItems: define("company-department-items"),
    CompanyLeadItems: define("company-lead-items"),
    CompanyUserItem: define("company-user-item"),
    CompanyUserItems: define("company-user-items"),
    GuestCompanyUserItem: define("guest-company-user-item"),
} as const;

/**
 * Companies
 */

export const useGetCompanyItem = (
    companyId: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<ICompany, Error>(
        [QueryKeys.CompanyItem, companyId],
        () => getCompanyItem(companyId),
        options
    );

export const useGetGuestCompanyItem = (
    slug: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<IGuestCompany, Error>(
        [QueryKeys.GuestCompanyItem, slug],
        () => getGuestCompanyItem(slug),
        options
    );

export const useUpdateCompanyItem = () =>
    useMutation(updateCompanyItem, {
        onSuccess: (updatedItem: ICompany, postedItem) => {
            queryClient.invalidateQueries([
                QueryKeys.CompanyItem,
                postedItem.id,
            ]);
        },
    });

export const useCreateCompanyItem = () => useMutation(createCompanyItem);

export const useGetCompanyItems = (
    page: number,
    search?: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<TPaginatedCompaniesResponse, Error>(
        [QueryKeys.CompanyItems, { page, search }],
        () => getCompanyItems(page, search),
        options
    );

/**
 * CompaniesVacancies
 */

export const useGetCompanyVacancyItems = (
    companyId: string,
    page: number,
    search?: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<vacancyTypes.TPaginatedVacanciesResponse, Error>(
        [QueryKeys.CompanyVacancyItems, { companyId, page, search }],
        () => getCompanyVacancyItems(companyId, page, search),
        options
    );

export const useGetCompanyVacancyItem = (
    companyId: string,
    vacancyId: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<vacancyTypes.IVacancy, Error>(
        [QueryKeys.CompanyVacancyItem, { companyId, vacancyId }],
        () => getCompanyVacancyItem(companyId, vacancyId),
        options
    );

export const useCreateCompanyVacancyItem = (companyId: string) =>
    useMutation((item: vacancyTypes.IVacancy) =>
        createCompanyVacancyItem(companyId, item)
    );

export const useUpdateCompanyVacancyItem = (companyId: string) =>
    useMutation((item: vacancyTypes.IVacancy) =>
        updateCompanyVacancyItem(companyId, item)
    );

/**
 * CompaniesDepartments
 */

export const useGetCompanyDepartmentItems = (
    companyId: string,
    page: number,
    search?: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<departmentTypes.TPaginatedDepartmentsResponse, Error>(
        [QueryKeys.CompanyDepartmentItems, { companyId, page, search }],
        () => getCompanyDepartmentItems(companyId, page, search),
        options
    );

/**
 * CompanyLeads
 */

export const useGetCompanyLeads = (
    companyId: string,
    page: number,
    options: commonTypes.IUseGetOptions
) =>
    useQuery<leadTypes.TPaginatedLeadsResponse, Error>(
        [QueryKeys.CompanyLeadItems, companyId, page],
        () => getCompanyLeads(companyId, page),
        options
    );

/**
 * CompaniesUsers
 */

export const useGetCompanyUserItem = (
    companyId: string,
    userId: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<userTypes.IUser, Error>(
        [QueryKeys.CompanyUserItem, { companyId, userId }],
        () => getCompanyUserItem(companyId, userId),
        options
    );

export const useGetCompanyUserItems = (
    companyId: string,
    query?: QueryBuilder,
    options?: commonTypes.IUseGetOptions
) => {
    const queryString = query?.get();

    return useQuery<userTypes.IUser[], Error>(
        [QueryKeys.CompanyUserItem, { companyId, queryString }],
        () => getCompanyUserItems(companyId, query),
        options
    );
};

export const useGetGuestCompanyUserItem = (
    companyId: string,
    userId: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<userTypes.IUser, Error>(
        [QueryKeys.GuestCompanyUserItem, { companyId, userId }],
        () => getGuestCompanyUserItem(companyId, userId),
        options
    );
