import * as React from "react";
import { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export interface ITextareaProps
    extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea: FC<ITextareaProps> = React.forwardRef<
    HTMLTextAreaElement,
    ITextareaProps
>(({ className, ...props }, ref) => {
    return (
        <textarea
            className={classMerge(
                "flex min-h-[80px] w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            ref={ref}
            {...props}
        />
    );
});
Textarea.displayName = "Textarea";

export { Textarea };
