import * as React from "react";
import { ISpinnerProps, Spinner } from "./Spinner";

import { classMerge } from "../../utils/styleUtils";

export interface IBoxedSpinnerProps extends ISpinnerProps {}

const outerStyle: React.CSSProperties = {
    boxShadow: "0px 4px 50px 0px rgba(0, 0, 0, 0.04)",
    border: "1px solid rgba(0, 0, 0, 0.05)",
};

export const BoxedSpinner = ({
    outerClassName,
    className,
    ...props
}: IBoxedSpinnerProps) => (
    <Spinner
        {...props}
        outerClassName={classMerge("rounded-full bg-white p-2", outerClassName)}
        outerStyle={outerStyle}
        className={classMerge("w-5 h-5 m-0", className)}
    />
);
