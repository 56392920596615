import { LogoWorck } from "@worck/ui";

export const PlainFooter = () => (
    <footer className="h-20 flex justify-center flex-col text-black text-opacity-30 text-sm font-normal mt-16 pb-16">
        <div className="flex items-center justify-center w-full">
            <LogoWorck className="w-24 inline-block" />
        </div>
        <ul className="flex gap-7 items-center justify-center mt-8">
            <li>
                <a href="">Privacy & Terms</a>
            </li>
            <li>
                <a href="">Contact Us</a>
            </li>
            <li>Copyright © 2023 Worck</li>
        </ul>
    </footer>
);
