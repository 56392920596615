const protocol = new URL(document.location.href).protocol;

export const config = {
    apiUrl: import.meta.env.VITE_PLATFORM_API_URL,
    appUrl: `${protocol}//${import.meta.env.VITE_PLATFORM_DOMAIN}`,
    pusherAppKey: import.meta.env.VITE_PUSHER_APP_KEY,
    pusherAppCluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    employerDomain: import.meta.env.VITE_EMPLOYER_DOMAIN || "app.worck.test",
    publisherDomain: import.meta.env.VITE_PUBLISHER_DOMAIN || "worck.test",
    applicantDomain:
        import.meta.env.VITE_APPLICANT_DOMAIN || "worck-careers.test",
    // applicantVacancyPreviewUrlTpl:
    //     import.meta.env.VITE_APPLICANT_VACANCY_PREVIEW_URL_TPL ||
    //     "https://{companySlug}.{applicantDomain}/preview/{vacancyId}",
    applicantVacancyPreviewUrlTpl:
        "https://{companySlug}.{applicantDomain}/preview/{vacancyId}",
    gitTag: import.meta.env.VITE_GIT_TAG,
    gitCommitDate: import.meta.env.VITE_GIT_COMMIT_DATE,
    facebookPublisherAppId: import.meta.env.VITE_FACEBOOK_PUBLISHER_APP_ID,
    sentryDsn: import.meta.env.VITE_PLATFORM_SENTRY_DSN,
};
