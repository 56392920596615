import { IAuthContext } from "@app/auth";
import { Roles } from "@app/rbac";
import { reactUtils } from "@ui/utils";
import { Fragment, lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import { RouteContainer, WithRoleAccessCheck } from "../Routes";
import { ContentWithNavigationTopbarLayout } from "../layouts/ContentWithNavigationTopbarLayout";
import { PublisherEntryLayout } from "../layouts/PublisherEntryLayout";

const PublisherSignInPage = reactUtils.reactLazyPreload(async () => {
    await PublisherSignInCodeConfirmPage.preload();

    return import("@app/publisher/pages/PublisherSignInPage").then(
        (module) => ({
            default: module.PublisherSignInPage,
        })
    );
});

const PublisherVacancySharePage = reactUtils.reactLazyPreload(() =>
    import("@app/vacancy/pages/PublisherVacancySharePage").then((module) => ({
        default: module.PublisherVacancySharePage,
    }))
);

const PublisherSignInCodeConfirmPage = reactUtils.reactLazyPreload(() =>
    import("@app/publisher/pages/PublisherSignInCodeConfirmPage").then(
        (module) => ({
            default: module.PublisherSignInCodeConfirmPage,
        })
    )
);

const PublisherDomainSelectionPage = reactUtils.reactLazyPreload(() =>
    import("@app/publisher/pages/PublisherDomainSelectionPage").then(
        (module) => ({
            default: module.PublisherDomainSelectionPage,
        })
    )
);

const DashboardOverview = lazy(() =>
    import("@app/dashboard/pages/DashboardOverview").then((module) => ({
        default: module.DashboardOverview,
    }))
);

const PublisherCompanyVacancyOverviewPage = lazy(() =>
    import("@app/company/pages/PublisherCompanyVacancyOverviewPage").then(
        (module) => ({
            default: module.PublisherCompanyVacancyOverviewPage,
        })
    )
);

const CompanyVacancyItemPage = lazy(() =>
    import("@app/company/pages/CompanyVacancyItemPage").then((module) => ({
        default: module.CompanyVacancyItemPage,
    }))
);

const UserCompanyItemPage = lazy(() =>
    import("@app/user/pages/UserCompanyItemPage").then((module) => ({
        default: module.UserCompanyItemPage,
    }))
);

export const publisherRoutes = ({
    auth,
    companySlug,
    companyId,
}: {
    auth: IAuthContext;
    companySlug?: string;
    companyId?: string;
}) => (
    <Route element={<RouteContainer />}>
        {!auth.authenticatedUser ? (
            <Fragment>
                <Route element={<PublisherEntryLayout />}>
                    <Route path="auth">
                        <Route
                            path="sign-in"
                            element={
                                companySlug ? (
                                    <PublisherSignInPage />
                                ) : (
                                    <PublisherDomainSelectionPage />
                                )
                            }
                        />
                        {/* <Route path="domain" element={<PublisherDomainSelectionPage />} /> */}
                        {auth.unauthenticatedUser ? (
                            <Route
                                path="verify"
                                element={<PublisherSignInCodeConfirmPage />}
                            />
                        ) : (
                            <Route
                                path="verify"
                                element={<Navigate to="/auth/sign-in" />}
                            />
                        )}
                    </Route>
                    {/* <Route path="*" element={<div>404 not found</div>} /> */}
                    <Route
                        path="*"
                        element={<Navigate to={"/auth/sign-in"} />}
                    />
                    <Route index element={<Navigate to={"/auth/sign-in"} />} />
                </Route>
            </Fragment>
        ) : (
            <Fragment>
                <Route
                    element={<WithRoleAccessCheck roles={[Roles.Publisher]} />}
                >
                    <Route
                        element={
                            <ContentWithNavigationTopbarLayout
                                authenticatedUser={auth.authenticatedUser}
                            />
                        }
                    >
                        <Route
                            path="vacancies/:vacancyId"
                            element={<PublisherVacancySharePage />}
                        />
                        <Route path="companies/:companyId/">
                            <Route
                                path="vacancies"
                                element={
                                    <PublisherCompanyVacancyOverviewPage />
                                }
                            />
                            {/* <Route
                                path="vacancies/:vacancyId"
                                element={<CompanyVacancyItemPage />}
                            /> */}
                        </Route>
                        <Route path="users/:userId/">
                            <Route
                                path="companies/:companyId"
                                element={<UserCompanyItemPage />}
                            />
                        </Route>
                    </Route>

                    <Route
                        index
                        element={
                            <Navigate
                                to={`/companies/${companyId}/vacancies`}
                            />
                        }
                    />
                    <Route
                        path="/auth/sign-in"
                        element={
                            <Navigate
                                to={`/companies/${companyId}/vacancies`}
                            />
                        }
                    />
                    <Route path="*" element={<div></div>} />
                </Route>
            </Fragment>
        )}
    </Route>
);
