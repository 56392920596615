import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const PublisherEntryLayout = () => {
    return (
        <div
            id="layout"
            className="h-full w-full bg-gradient-to-r from-[#FFF_0%], from-[#fff_51.54%], from-[#FDFDFD_53.13%] to-[[#FDFDFD_100%]"
            // style={{
            //     background:
            //         "linear-gradient(90deg, #FFF 0%, #FFF 51.54%, #FDFDFD 53.13%, #FDFDFD 100%)",
            // }}
        >
            <main className="h-full">
                <Suspense>
                    <Outlet />
                </Suspense>
            </main>
        </div>
    );
};
