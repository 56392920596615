// import {
//     BaseColors,
//     colorPalette,
//     getColorClassNames,
//     sumNumericArray,
// } from "lib";
// import { Color, ValueFormatter } from "../../../lib/inputTypes";

export const parseData = (data: any[], colors: string[]) =>
    data.map((dataPoint: any, idx: number) => {
        const baseColor = idx < colors.length ? colors[idx] : "fill-gray-400";

        return {
            ...dataPoint,
            // explicitly adding color key if not present for tooltip coloring
            color: baseColor,
            className: baseColor,
        };
    });

export const sumNumericArray = (arr: number[]) =>
    arr.reduce((prefixSum, num) => prefixSum + num, 0);

const calculateDefaultLabel = (data: any[], category: string) =>
    sumNumericArray(data.map((dataPoint) => dataPoint[category]));

export const parseLabelInput = (
    labelInput: string | undefined,
    valueFormatter: any,
    data: any[],
    category: string
) =>
    labelInput
        ? labelInput
        : valueFormatter(calculateDefaultLabel(data, category));
