import { UtilsUrl } from "@hotelchamp/common";
import React from "react";
import { config } from "../config";
import { AppFlows } from "../constants/AppFlows";
import { IAppState } from "../types";

export type TAppStateResolver = (state: IAppState) => IAppState;
export interface IAppStateContext {
    state: IAppState;
    setState: React.Dispatch<React.SetStateAction<IAppState>>;
    updateState: (state: Partial<IAppState>) => void;
}

const resolveActiveAppFlowOrFail = (): AppFlows => {
    const url = new URL(document.location.href);
    const hostname = url.hostname;

    const resolvedAppFlow =
        hostname === config.employerDomain ||
        hostname.substring(hostname.indexOf(".") + 1) === config.employerDomain
            ? AppFlows.Employer
            : AppFlows.Publisher;

    if (!resolvedAppFlow) {
        throw new Error("Could not resolve active AppFlow");
    }

    return resolvedAppFlow;
};

// resolve app state subdomain which is the subdomain of the app url
const subdomain =
    new URL(config.appUrl).hostname !== new URL(document.location.href).hostname
        ? UtilsUrl.getSubdomain(document.location.href, {
              ignoreWww: true,
          })
        : null;

export const INITIAL_APP_STATE: IAppState = {
    subdomain,
    isLoading: false,
    activeAppFlow: resolveActiveAppFlowOrFail(),
};

export const AppStateContext = React.createContext<IAppStateContext>({
    state: INITIAL_APP_STATE,
    setState: () => {
        throw new Error("AppStateContext not ready yet");
    },
    updateState: () => {
        throw new Error("AppStateContext not ready yet");
    },
});

AppStateContext.displayName = "AppStateContext";
