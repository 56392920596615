"use client";

import * as TabsPrimitive from "@radix-ui/react-tabs";
import React, { ComponentProps, FC } from "react";

import { classMerge } from "../../utils/styleUtils";

const Tabs = TabsPrimitive.Root;

type TTabsProps = ComponentProps<typeof Tabs>;

export type TTabsListProps = React.ComponentPropsWithoutRef<
    typeof TabsPrimitive.List
>;

const TabsList: FC<TTabsListProps> = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.List>,
    TTabsListProps
>(({ className, ...props }, ref) => (
    <TabsPrimitive.List
        ref={ref}
        className={classMerge(
            "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
            className
        )}
        {...props}
    />
));
TabsList.displayName = TabsPrimitive.List.displayName;

export type TTabsTriggerProps = React.ComponentPropsWithoutRef<
    typeof TabsPrimitive.Trigger
>;

const TabsTrigger: FC<TTabsTriggerProps> = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Trigger>,
    TTabsTriggerProps
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Trigger
        ref={ref}
        className={classMerge(
            "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
            className
        )}
        {...props}
    />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

export type TTabsContentProps = React.ComponentPropsWithoutRef<
    typeof TabsPrimitive.Content
>;

const TabsContent: FC<TTabsContentProps> = React.forwardRef<
    React.ElementRef<typeof TabsPrimitive.Content>,
    TTabsContentProps
>(({ className, ...props }, ref) => (
    <TabsPrimitive.Content
        ref={ref}
        className={classMerge(
            "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2",
            className
        )}
        {...props}
    />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsContent, TabsList, TabsTrigger, type TTabsProps };
