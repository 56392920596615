"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TCheckboxProps = React.ComponentPropsWithoutRef<
    typeof CheckboxPrimitive.Root
>;

const Checkbox: FC<TCheckboxProps> = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    TCheckboxProps
>(({ className, ...props }, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={classMerge(
            "peer h-4 w-4 shrink-0 rounded-sm border border-theme-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-theme-primary data-[state=checked]:text-primary-foreground",
            className
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={classMerge(
                "flex items-center justify-center text-current"
            )}
        >
            <Check className="h-4 w-4" />
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
