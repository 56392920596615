import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            useErrorBoundary: true,
            refetchOnWindowFocus: false,
            // retry(failureCount, error) {
            //     // if ((error as AxiosError).response?.status === 401) return false;
            //     // else if (failureCount < 2) return true;
            //     // else return false;
            //     return false;
            // },
        },
    },
});
