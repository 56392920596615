import { cva } from "class-variance-authority";

export const DEFAULT_VARIANT_VARIANT = "default";
export const DEFAULT_VARIANT_SIZE = "default";

export const buttonVariants = cva(
    "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 disabled:cursor-not-allowed",
    {
        variants: {
            variant: {
                default:
                    "bg-theme-primary text-primary-foreground hover:bg-theme-primary/90",
                destructive:
                    "bg-destructive text-destructive-foreground hover:bg-destructive/90",
                outline: "border border-input hover:bg-muted",
                secondary:
                    "bg-theme-secondary text-secondary-foreground hover:bg-theme-secondary/90",
                ghost: "hover:bg-accent hover:text-accent-foreground",
                link: "text-theme-primary underline-offset-4 hover:underline",
            },
            size: {
                default: "h-10 px-4 py-2",
                sm: "h-9 rounded-md px-3",
                lg: "h-11 rounded-md px-8",
            },
        },
        defaultVariants: {
            variant: DEFAULT_VARIANT_VARIANT,
            size: DEFAULT_VARIANT_SIZE,
        },
    }
);
