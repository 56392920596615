import { AuthProvider } from "@app/auth";
import { I18nextProvider } from "react-i18next";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { AppStateContextProvider } from "../context/AppStateContextProvider";
import { i18next } from "../services/i18n";
import { queryClient } from "../services/queryClient";

export interface IAppProps {
    children: React.ReactNode;
}

export const App = ({ children }: IAppProps) => {
    return (
        <QueryClientProvider client={queryClient}>
            <AppStateContextProvider>
                <I18nextProvider i18n={i18next}>
                    <Router>
                        <AuthProvider>{children}</AuthProvider>
                    </Router>
                </I18nextProvider>
                <ReactQueryDevtools
                    position="bottom-right"
                    initialIsOpen={false}
                />
            </AppStateContextProvider>
        </QueryClientProvider>
    );
};
