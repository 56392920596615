import { axios } from "@app/app";
import { config } from "./config";
import { VerifyOneTimeLoginTokenMethods } from "./constants/VerifyOneTimeLoginTokenMethods";
import { IAuthenticatedUser, IUnauthenticatedUser } from "./types";

export const initializeCsrfProtection = async (): Promise<void> => {
    await axios.get(config.sanctumUrl);
};

export interface ISignUpInPublisherProps {
    email: string;
    remember?: boolean;
    company_id: string;
}

export const signUpInPublisher = async (
    props: ISignUpInPublisherProps
): Promise<IUnauthenticatedUser> => {
    await initializeCsrfProtection();

    const response = await axios.post("/auth/publisher/register", props);

    return response.data.data;
};

export const getAuthenticatedSessionUser =
    async (): Promise<IAuthenticatedUser> => {
        const response = await axios.get("auth/authenticated-session-user");

        return response.data.data;
    };

export const hasAuthenticatedSession = async (): Promise<boolean> => {
    const response = await axios.get<{
        hasAuthenticatedSession: boolean;
    }>("auth/has-authenticated-session");

    return response.data.hasAuthenticatedSession;
};

export interface IVerifyOneTimeLoginTokenProps {
    userId: string;
    verificationCode: string;
    method: VerifyOneTimeLoginTokenMethods;
}

export const verifyOneTimeLoginToken = async (
    props: IVerifyOneTimeLoginTokenProps
): Promise<IAuthenticatedUser> => {
    const response = await axios.post("/auth/verify-otl-token", props, {
        headers: {
            Authorization: `Bearer ${props.verificationCode}`,
        },
    });

    return response.data.data;
};

/**
 * Check below (old)
 * @returns
 */

export interface ILoginProps {
    email: string;
    password: string;
    remember?: boolean;
}

export const login = async (
    props: ILoginProps
): Promise<IAuthenticatedUser> => {
    await initializeCsrfProtection();

    const response = await axios.post("auth/login", props);

    return response.data.data;
};

export const logout = async () => {
    await axios.post("auth/logout");
};
