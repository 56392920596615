"use client";

import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

/**
 * A vertically stacked set of interactive headings that each reveal a section of content.
 *
 * @see https://ui.shadcn.com/docs/components/accordion
 */
const Accordion = AccordionPrimitive.Root;

export type TAccordionItemProps = React.ComponentPropsWithoutRef<
    typeof AccordionPrimitive.Item
>;

const AccordionItem: FC<TAccordionItemProps> = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    TAccordionItemProps
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={classMerge("border-b", className)}
        {...props}
    />
));
AccordionItem.displayName = "AccordionItem";

export type TAccordionTriggerProps = React.ComponentPropsWithoutRef<
    typeof AccordionPrimitive.Trigger
>;

const AccordionTrigger: FC<TAccordionTriggerProps> = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    TAccordionTriggerProps
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={classMerge(
                "flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180",
                className
            )}
            {...props}
        >
            {children}
            <ChevronDown className="h-4 w-4 shrink-0 transition-transform duration-200" />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

export type TAccordionContent = React.ComponentPropsWithoutRef<
    typeof AccordionPrimitive.Content
>;

const AccordionContent: FC<TAccordionContent> = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    TAccordionContent
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className={classMerge(
            "overflow-hidden text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down",
            className
        )}
        {...props}
    >
        <div className="pb-4 pt-0">{children}</div>
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionContent, AccordionItem, AccordionTrigger };
