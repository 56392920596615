import { LogoWorck } from "@ui";
import { useState } from "react";

export const TipLeadThankYouPage = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const navigation = [
        { name: "Product", href: "#" },
        { name: "Features", href: "#" },
        { name: "Resources", href: "#" },
        { name: "Company", href: "#" },
    ];
    const stats = [
        { label: "Transactions every 24 hours", value: "44 million" },
        { label: "Assets under holding", value: "$119 trillion" },
        { label: "New users annually", value: "46,000" },
    ];
    const values = [
        {
            name: "Wat is de status van mijn kandidaat?",
            description:
                "Vanwege de privacywetgeving mogen we helaas niet met je delen hoe de kandidaat heeft gereageerd of hij/zij nog in procedure is. Je kunt uiteraard wel zijn of haar LinkedIn-profiel in de gaten houden!",
        },
        {
            name: "Hoe groot is de kans dat ik betaald krijg?",
            description:
                "Die kans is best groot! Vacatures die via WORCK worden uitgezet worden ook bijna allemaal via WORCK ingevuld. Dus voor bij bijna iedere vacature volgt een uitbetaling! Het hangt natuurlijk van jouw kandidaat af jij de beloning jouw kant op komt!",
        },
        {
            name: "Hoe lang kan dit duren?",
            description:
                "Een sollicitatieprocedure kan meerdere weken en soms zelfs meerdere maanden duren.",
        },
    ];
    const team = [
        {
            name: "Michael Foster",
            role: "Co-Founder / CTO",
            imageUrl:
                "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
        },
        // More people...
    ];
    const blogPosts = [
        {
            id: 1,
            title: "Vel expedita assumenda placeat aut nisi optio voluptates quas",
            href: "#",
            description:
                "Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.",
            imageUrl:
                "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80",
            date: "Mar 16, 2020",
            datetime: "2020-03-16",
            author: {
                name: "Michael Foster",
                imageUrl:
                    "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
            },
        },
        // More posts...
    ];
    const footerNavigation = {
        main: [
            { name: "About", href: "#" },
            { name: "Blog", href: "#" },
            { name: "Jobs", href: "#" },
            { name: "Press", href: "#" },
            { name: "Accessibility", href: "#" },
            { name: "Partners", href: "#" },
        ],
        social: [
            {
                name: "Facebook",
                href: "#",
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            {
                name: "Instagram",
                href: "#",
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            {
                name: "X",
                href: "#",
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
                    </svg>
                ),
            },
            {
                name: "GitHub",
                href: "#",
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
            {
                name: "YouTube",
                href: "#",
                icon: (props: any) => (
                    <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                        <path
                            fillRule="evenodd"
                            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                            clipRule="evenodd"
                        />
                    </svg>
                ),
            },
        ],
    };

    return (
        <div className="bg-white">
            <main className="isolate">
                {/* Hero section */}
                <div className="relative isolate -z-10">
                    <svg
                        aria-hidden="true"
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg
                            x="50%"
                            y={-1}
                            className="overflow-visible fill-gray-50"
                        >
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect
                            fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                            width="100%"
                            height="100%"
                            strokeWidth={0}
                        />
                    </svg>

                    <div className="overflow-hidden ">
                        <header className="absolute inset-x-0 top-0 z-50">
                            <nav
                                aria-label="Global"
                                className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
                            >
                                <div className="flex lg:flex-1">
                                    <LogoWorck className="h-6 w-auto" />
                                </div>

                                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                                    <a
                                        href="mailto:vragen@worck.com"
                                        className="text-sm/6 font-normal text-gray-900"
                                    >
                                        Neem gerust contact op met het team van
                                        WORCK via{" "}
                                        <span className="text-primary font-semibold hover:underline underline-offset-4">
                                            vragen@worck.com.
                                            <span aria-hidden="true">
                                                &rarr;
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            </nav>
                        </header>
                        <div className="mx-auto max-w-7xl px-6 pt-20 sm:pt-20 lg:px-8">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                <div className="relative w-full lg:max-w-xl lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="text-pretty text-2xl font-semibold tracking-tight text-primary sm:text-6xl">
                                        Wat zijn de volgende stappen?
                                    </h1>
                                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base/7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none  lg:gap-x-16">
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-foreground">
                                                <svg
                                                    className="absolute top-1 left-1 size-5 text-primary w-4"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    data-slot="icon"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4.606 12.97a.75.75 0 0 1-.134 1.051 2.494 2.494 0 0 0-.93 2.437 2.494 2.494 0 0 0 2.437-.93.75.75 0 1 1 1.186.918 3.995 3.995 0 0 1-4.482 1.332.75.75 0 0 1-.461-.461 3.994 3.994 0 0 1 1.332-4.482.75.75 0 0 1 1.052.134Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M5.752 12A13.07 13.07 0 0 0 8 14.248v4.002c0 .414.336.75.75.75a5 5 0 0 0 4.797-6.414 12.984 12.984 0 0 0 5.45-10.848.75.75 0 0 0-.735-.735 12.984 12.984 0 0 0-10.849 5.45A5 5 0 0 0 1 11.25c.001.414.337.75.751.75h4.002ZM13 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Wij of de recruiter benaderen de
                                                kandidaat{" "}
                                            </dt>

                                            <dd className="inline text-foreground">
                                                die je hebt getipt om te bepalen
                                                of hij/zij past bij de vacature
                                                en geïnteresseerd is in een
                                                gesprek.
                                            </dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-foreground">
                                                <svg
                                                    className="absolute top-1 left-1 size-5 text-foreground w-4"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    data-slot="icon"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4.606 12.97a.75.75 0 0 1-.134 1.051 2.494 2.494 0 0 0-.93 2.437 2.494 2.494 0 0 0 2.437-.93.75.75 0 1 1 1.186.918 3.995 3.995 0 0 1-4.482 1.332.75.75 0 0 1-.461-.461 3.994 3.994 0 0 1 1.332-4.482.75.75 0 0 1 1.052.134Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M5.752 12A13.07 13.07 0 0 0 8 14.248v4.002c0 .414.336.75.75.75a5 5 0 0 0 4.797-6.414 12.984 12.984 0 0 0 5.45-10.848.75.75 0 0 0-.735-.735 12.984 12.984 0 0 0-10.849 5.45A5 5 0 0 0 1 11.25c.001.414.337.75.751.75h4.002ZM13 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Als de kandidaat interesse heeft
                                                en geschikt lijkt
                                            </dt>

                                            <dd className="inline text-foreground">
                                                , stellen we hem/haar voor aan
                                                de opdrachtgever (het bedrijf
                                                dat de vacature heeft uitgezet).
                                            </dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-foreground">
                                                <svg
                                                    className="absolute top-1 left-1 size-5 text-foreground w-4"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    data-slot="icon"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4.606 12.97a.75.75 0 0 1-.134 1.051 2.494 2.494 0 0 0-.93 2.437 2.494 2.494 0 0 0 2.437-.93.75.75 0 1 1 1.186.918 3.995 3.995 0 0 1-4.482 1.332.75.75 0 0 1-.461-.461 3.994 3.994 0 0 1 1.332-4.482.75.75 0 0 1 1.052.134Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M5.752 12A13.07 13.07 0 0 0 8 14.248v4.002c0 .414.336.75.75.75a5 5 0 0 0 4.797-6.414 12.984 12.984 0 0 0 5.45-10.848.75.75 0 0 0-.735-.735 12.984 12.984 0 0 0-10.849 5.45A5 5 0 0 0 1 11.25c.001.414.337.75.751.75h4.002ZM13 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                De uiteindelijke beslissing{" "}
                                            </dt>

                                            <dd className="inline text-foreground">
                                                of de kandidaat wordt
                                                aangenomen, ligt bij de
                                                opdrachtgever.
                                            </dd>
                                        </div>
                                        <div className="relative pl-9">
                                            <dt className="inline font-semibold text-foreground">
                                                <svg
                                                    className="absolute top-1 left-1 size-5 text-foreground w-4"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                    data-slot="icon"
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M4.606 12.97a.75.75 0 0 1-.134 1.051 2.494 2.494 0 0 0-.93 2.437 2.494 2.494 0 0 0 2.437-.93.75.75 0 1 1 1.186.918 3.995 3.995 0 0 1-4.482 1.332.75.75 0 0 1-.461-.461 3.994 3.994 0 0 1 1.332-4.482.75.75 0 0 1 1.052.134Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        d="M5.752 12A13.07 13.07 0 0 0 8 14.248v4.002c0 .414.336.75.75.75a5 5 0 0 0 4.797-6.414 12.984 12.984 0 0 0 5.45-10.848.75.75 0 0 0-.735-.735 12.984 12.984 0 0 0-10.849 5.45A5 5 0 0 0 1 11.25c.001.414.337.75.751.75h4.002ZM13 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
                                                        clip-rule="evenodd"
                                                    ></path>
                                                </svg>
                                                Zodra de kandidaat begint{" "}
                                            </dt>

                                            <dd className="inline text-foreground">
                                                bij de opdrachtgever, betaalt
                                                die de vergoeding aan WORCK en
                                                betalen wij jou uit.
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                    <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src="https://framerusercontent.com/images/SzCUxHKTz5chcmGI2yJW89DZeo.jpeg"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src="https://framerusercontent.com/images/85M1GJqwmvs7BEQgX1MAGwEY3k.jpeg"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src="https://framerusercontent.com/images/Gdkqb7cNbEUcQFLw7h0usen7DkM.jpeg"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative">
                                            <img
                                                alt=""
                                                src="https://framerusercontent.com/images/SzCUxHKTz5chcmGI2yJW89DZeo.jpeg"
                                                className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Values section */}
                <div className="mx-auto max-w-7xl px-6 -mt-8 lg:px-8 mb-16 pb-16">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-pretty text-3xl font-semibold tracking-tight text-primary sm:text-4xl">
                            Veel gestelde vragen:
                        </h2>
                        {/* <p className="mt-6 text-lg/8 text-gray-600">
                            Lorem ipsum dolor sit amet consect adipisicing elit.
                            Possimus magnam voluptatum cupiditate veritatis in
                            accusamus quisquam.
                        </p> */}
                    </div>
                    <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base/7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        {values.map((value) => (
                            <div key={value.name}>
                                <dt className="font-semibold text-gray-900">
                                    {value.name}
                                </dt>
                                <dd className="mt-1 text-gray-600">
                                    {value.description}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </main>
        </div>
    );
};
