"use client";

import * as ProgressPrimitive from "@radix-ui/react-progress";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TProgressProps = React.ComponentPropsWithoutRef<
    typeof ProgressPrimitive.Root
>;

const Progress: FC<TProgressProps> = React.forwardRef<
    React.ElementRef<typeof ProgressPrimitive.Root>,
    TProgressProps
>(({ className, value, ...props }, ref) => (
    <ProgressPrimitive.Root
        ref={ref}
        className={classMerge(
            "relative h-4 w-full overflow-hidden rounded-full bg-theme-secondary",
            className
        )}
        {...props}
    >
        <ProgressPrimitive.Indicator
            className="h-full w-full flex-1 bg-theme-primary transition-all"
            style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
        />
    </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
