"use client";

import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import * as React from "react";
import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TScrollAreaProps = React.ComponentPropsWithoutRef<
    typeof ScrollAreaPrimitive.Root
>;

const ScrollArea: FC<TScrollAreaProps> = React.forwardRef<
    React.ElementRef<typeof ScrollAreaPrimitive.Root>,
    TScrollAreaProps
>(({ className, children, ...props }, ref) => (
    <ScrollAreaPrimitive.Root
        ref={ref}
        className={classMerge("relative overflow-hidden", className)}
        {...props}
    >
        <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">
            {children}
        </ScrollAreaPrimitive.Viewport>
        <ScrollBar />
        <ScrollAreaPrimitive.Corner />
    </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

export type TScrollBarProps = React.ComponentPropsWithoutRef<
    typeof ScrollAreaPrimitive.ScrollAreaScrollbar
>;

const ScrollBar: FC<TScrollBarProps> = React.forwardRef<
    React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
    TScrollBarProps
>(({ className, orientation = "vertical", ...props }, ref) => (
    <ScrollAreaPrimitive.ScrollAreaScrollbar
        ref={ref}
        orientation={orientation}
        className={classMerge(
            "flex touch-none select-none transition-colors",
            orientation === "vertical" &&
                "h-full w-2.5 border-l border-l-transparent p-[1px]",
            orientation === "horizontal" &&
                "h-2.5 border-t border-t-transparent p-[1px]",
            className
        )}
        {...props}
    >
        <ScrollAreaPrimitive.ScrollAreaThumb className="relative flex-1 rounded-full bg-border" />
    </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
