import { authTypes } from "@app/auth";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export interface ISettingPageWithNavigationSidebarLayoutProps {
    authenticatedUser?: authTypes.IAuthenticatedUser;
}

export const SettingPageWithNavigationSidebarLayout = ({
    authenticatedUser,
}: ISettingPageWithNavigationSidebarLayoutProps) => {
    return (
        <div id="dede" className="bg-white">
            {/* <Sidebar authenticatedUser={authenticatedUser} /> */}
            <main className="lg:pl-20">
                <div className="xl:pl-64">
                    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                        <Suspense>
                            <Outlet />
                        </Suspense>
                    </div>
                </div>
            </main>
            <aside className="fixed inset-y-0 left-20 hidden w-64 overflow-y-auto border-r px-4 py-6 sm:px-6 lg:px-4 xl:block">
                <div className="w-96 border-r mr-8 overflow-hidden px-3">
                    <div className="text-2xl font-semibold mb-14">Settings</div>
                    <nav className="flex flex-1 flex-col">
                        <ul
                            role="list"
                            className="flex flex-1 flex-col gap-y-7"
                        >
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    Company
                                </div>
                                <ul role="list" className="mt-2 space-y-1">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Company
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Departments
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    Users & Permissions
                                </div>
                                <ul
                                    role="list"
                                    className="-mx-2 mt-2 space-y-1"
                                >
                                    <li>
                                        <a
                                            href="#"
                                            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Users
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Publisher
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    Settings
                                </div>
                                <ul
                                    role="list"
                                    className="-mx-2 mt-2 space-y-1"
                                >
                                    <li>
                                        <a
                                            href="#"
                                            className="hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Company settings
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="bg-muted hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Integrations
                                            </span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                E-mail setup
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div className="text-xs font-semibold leading-6 text-gray-400">
                                    Data & Privacy
                                </div>
                                <ul
                                    role="list"
                                    className="-mx-2 mt-2 space-y-1"
                                >
                                    <li>
                                        <a
                                            href="#"
                                            className="text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                            x-state:on="Current"
                                            x-state:off="Default"
                                            x-state-description='Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50"'
                                        >
                                            <span className="truncate">
                                                Complaince request
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    );
};
