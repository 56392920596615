import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export const FullScreenLayout = () => {
    return (
        <div
            id="layout"
            className="h-full w-full flex-auto mx-auto p-5 lg:px-8 justify-between"
        >
            <main className="mb-auto">
                <Suspense>
                    <Outlet />
                </Suspense>
            </main>
        </div>
    );
};
