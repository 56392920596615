import { authTypes } from "@app/auth";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./sidebar/Sidebar";

export interface IContentWithNavigationSidebarLayoutProps {
    authenticatedUser: authTypes.IAuthenticatedUser;
}

export const ContentWithNavigationSidebarLayout = ({
    authenticatedUser,
}: IContentWithNavigationSidebarLayoutProps) => (
    <div className="bg-white h-full">
        <Sidebar authenticatedUser={authenticatedUser} />
        <main className="lg:pl-20">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-8">
                <Suspense>
                    <Outlet />
                </Suspense>
            </div>
        </main>
    </div>
);
