import React, { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export type TTableProps = React.HTMLAttributes<HTMLTableElement>;

const Table: FC<TTableProps> = React.forwardRef<HTMLTableElement, TTableProps>(
    ({ className, ...props }, ref) => (
        <div className="relative w-full overflow-auto">
            <table
                ref={ref}
                className={classMerge(
                    "w-full caption-bottom text-sm table-auto",
                    className
                )}
                {...props}
            />
        </div>
    )
);
Table.displayName = "Table";

export type TTableHeaderProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableHeader: FC<TTableHeaderProps> = React.forwardRef<
    HTMLTableSectionElement,
    TTableHeaderProps
>(({ className, ...props }, ref) => (
    <thead
        ref={ref}
        className={classMerge("[&_tr]:border-b", className)}
        {...props}
    />
));
TableHeader.displayName = "TableHeader";

export type TTableBodyProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableBody: FC<TTableBodyProps> = React.forwardRef<
    HTMLTableSectionElement,
    TTableBodyProps
>(({ className, ...props }, ref) => (
    <tbody
        ref={ref}
        className={classMerge("[&_tr:last-child]:border-0", className)}
        {...props}
    />
));
TableBody.displayName = "TableBody";

export type TTableFooterProps = React.HTMLAttributes<HTMLTableSectionElement>;

const TableFooter: FC<TTableFooterProps> = React.forwardRef<
    HTMLTableSectionElement,
    TTableFooterProps
>(({ className, ...props }, ref) => (
    <tfoot
        ref={ref}
        className={classMerge(
            "bg-theme-primary font-medium text-primary-foreground",
            className
        )}
        {...props}
    />
));
TableFooter.displayName = "TableFooter";

export type TTableRowProps = React.HTMLAttributes<HTMLTableRowElement>;

const TableRow: FC<TTableRowProps> = React.forwardRef<
    HTMLTableRowElement,
    TTableRowProps
>(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={classMerge(
            "border-b transition-colors hover:bg-muted data-[state=selected]:bg-muted",
            className
        )}
        {...props}
    />
));
TableRow.displayName = "TableRow";

export type TTableHeadProps = React.ThHTMLAttributes<HTMLTableCellElement>;

const TableHead: FC<TTableHeadProps> = React.forwardRef<
    HTMLTableCellElement,
    TTableHeadProps
>(({ className, ...props }, ref) => (
    <th
        ref={ref}
        className={classMerge(
            "h-12 px-4 text-left align-middle font-medium text-foreground/70 [&:has([role=checkbox])]:pr-0",
            className
        )}
        {...props}
    />
));
TableHead.displayName = "TableHead";

export type TTableCellProps = React.TdHTMLAttributes<HTMLTableCellElement>;

const TableCell: FC<TTableCellProps> = React.forwardRef<
    HTMLTableCellElement,
    TTableCellProps
>(({ className, ...props }, ref) => (
    <td
        ref={ref}
        className={classMerge(
            "p-4 align-middle [&:has([role=checkbox])]:pr-0 whitespace-nowrap",
            className
        )}
        {...props}
    />
));
TableCell.displayName = "TableCell";

export type TTableCaptionProps = React.HTMLAttributes<HTMLTableCaptionElement>;

const TableCaption: FC<TTableCaptionProps> = React.forwardRef<
    HTMLTableCaptionElement,
    TTableCaptionProps
>(({ className, ...props }, ref) => (
    <caption
        ref={ref}
        className={classMerge("mt-4 text-sm text-muted-foreground", className)}
        {...props}
    />
));
TableCaption.displayName = "TableCaption";

export {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
};
