import * as React from "react";

import { classMerge } from "../../utils/styleUtils";

export interface ISpinnerProps extends React.HTMLAttributes<SVGElement> {
    outerClassName?: string;
    outerStyle?: React.CSSProperties;
}

export const Spinner = ({
    className,
    outerClassName,
    outerStyle,
    ...props
}: ISpinnerProps) => (
    <div
        role="status"
        className={classMerge("inline-block", outerClassName)}
        style={outerStyle}
    >
        <svg
            viewBox="0 0 24 24"
            fill="none"
            className={classMerge(
                "w-8 h-8 animate-spin text-theme-primary",
                className
            )}
            {...props}
        >
            <path
                d="M20.0001 12C20.0001 13.3811 19.6425 14.7386 18.9623 15.9405C18.282 17.1424 17.3022 18.1477 16.1182 18.8587C14.9341 19.5696 13.5862 19.9619 12.2056 19.9974C10.825 20.0328 9.45873 19.7103 8.23975 19.0612"
                stroke="currentColor"
                strokeWidth="4"
                strokeLinecap="round"
            />
        </svg>
        <span className="sr-only">Loading...</span>
    </div>
);
