import { authTypes } from "@app/auth";
import { FlashyHeader } from "@app/common";
import { companyTypes } from "@app/company";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classMerge } from "@ui/utils";
import {
    Avatar,
    AvatarImage,
    AvatarInitialsFallback,
    CircleLogoWorck,
    Flex,
    LogoWorck,
} from "@worck/ui";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const userNavigation = [
    // { name: "Your Profile", href: "#" },
    // { name: "Settings", href: "#" },
    { name: "Logout", to: "/logout" },
];

export interface ITopbarProps {
    authenticatedUser: authTypes.IAuthenticatedUser;
    company?: companyTypes.ICompany | companyTypes.IGuestCompany;
}

export const Topbar = ({ authenticatedUser, company }: ITopbarProps) => {
    const { t } = useTranslation("app");

    return (
        <Disclosure as="nav" className="border-b bg-white py-3">
            {({ open }) => (
                <Fragment>
                    {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> */}
                    <div className="mx-auto max-w-7xl px-3 sm:px-6 lg:px-8">
                        <Flex
                            className="h-16"
                            justifyContent={"justify-between"}
                        >
                            <Flex alignItems={"items-center"}>
                                <Flex
                                    className="flex-shrink-0 gap-x-10 sm:gap-x-10"
                                    alignItems={"items-center"}
                                    justifyContent={"justify-start"}
                                >
                                    {company?.logo?.original_url && (
                                        <img
                                            className="block h-10 w-auto max-h-4 max-w-[10em] lg:hidden"
                                            src={company?.logo?.original_url}
                                            alt={company?.name}
                                        />
                                    )}

                                    {company?.logo?.original_url && (
                                        <img
                                            className="hidden h-10 w-auto max-h-6 max-w-[15em] lg:block"
                                            src={company?.logo?.original_url}
                                            alt={company?.name}
                                        />
                                    )}

                                    <FlashyHeader className="text-xl leading-tight whitespace-nowrap border-r pr-10">
                                        {t("topBar.appTitle")}
                                    </FlashyHeader>

                                    <Flex
                                        alignItems={"items-center"}
                                        justifyContent={"justify-start"}
                                        className="hidden sm:block text-center text-gray-500 text-xs font-normal leading-snug gap-x-1"
                                    >
                                        <span>Powered by </span>
                                        <LogoWorck className="h-4 hidden md:inline-block" />
                                        <CircleLogoWorck className="h-6 sm:ml-2 inline-block md:hidden" />
                                    </Flex>
                                </Flex>
                            </Flex>
                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                {/* <CircleLogoWorck
                                    className="w-6 h-6 ml-3"
                                    aria-hidden="true"
                                /> */}
                                {/* <button
                                    type="button"
                                    className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-ring-primary focus:ring-offset-2"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">
                                        View notifications
                                    </span>
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button> */}

                                {/* Profile dropdown */}
                                <Menu as="div" className="relative ml-3">
                                    <div>
                                        <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-theme-ring-primary focus:ring-offset-2">
                                            <span className="absolute -inset-1.5" />
                                            <span className="sr-only">
                                                Open menu
                                            </span>
                                            <Avatar className="h-8 w-8">
                                                <AvatarImage
                                                    src={
                                                        authenticatedUser.avatar
                                                            ?.original_url
                                                    }
                                                    alt="Avatar"
                                                />
                                                <AvatarInitialsFallback
                                                    name={
                                                        authenticatedUser.name ||
                                                        authenticatedUser.email
                                                    }
                                                />
                                            </Avatar>
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }) => (
                                                        <Link
                                                            to={item.to}
                                                            className={classMerge(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "block px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                        >
                                                            {item.name}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-ring-primary focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    {open ? (
                                        <XMarkIcon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <Bars3Icon
                                            className="block h-6 w-6"
                                            aria-hidden="true"
                                        />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </Flex>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        {/* <div className="space-y-1 pb-3 pt-2">
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    key={item.name}
                                    as="a"
                                    href={item.href}
                                    className={classMerge(
                                        item.current
                                            ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                                            : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                                    )}
                                    aria-current={
                                        item.current ? "page" : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div> */}

                        <div className="border-t pb-3 pt-4">
                            <div className="flex items-center px-4">
                                <div className="flex-shrink-0">
                                    <Avatar className="h-12 w-12">
                                        <AvatarImage
                                            src={
                                                authenticatedUser.avatar
                                                    ?.original_url
                                            }
                                            alt="Avatar"
                                        />
                                        <AvatarInitialsFallback
                                            name={
                                                authenticatedUser.name ||
                                                authenticatedUser.email
                                            }
                                        />
                                    </Avatar>
                                </div>
                                <div className="ml-3">
                                    <div className="text-base font-medium text-gray-800">
                                        {authenticatedUser.name ||
                                            authenticatedUser.email.split(
                                                "@"
                                            )[0]}
                                    </div>
                                    <div className="text-sm font-medium text-gray-500">
                                        {authenticatedUser.email}
                                    </div>
                                </div>
                                {/* <button
                                    type="button"
                                    className="relative ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-ring-primary focus:ring-offset-2"
                                >
                                    <span className="absolute -inset-1.5" />
                                    <span className="sr-only">
                                        Notificaties bekijken
                                    </span>
                                    <BellIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button> */}
                            </div>
                            <div className="mt-3 space-y-1">
                                {userNavigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as={Link}
                                        to={item.to}
                                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </div>
                    </Disclosure.Panel>
                </Fragment>
            )}
        </Disclosure>
    );
};
