import React, { useCallback, useState } from "react";
import { IAppState } from "../types";
import {
    AppStateContext,
    IAppStateContext,
    INITIAL_APP_STATE,
} from "./AppStateContext";

export interface IAuthLoaderProviderProps {
    children?: React.ReactNode | React.ReactNode[];
}

export function AppStateContextProvider({
    children,
}: IAuthLoaderProviderProps) {
    const [state, setState] = useState<IAppState>(INITIAL_APP_STATE);

    const updateState = useCallback(
        (nextState: Partial<IAppState>) =>
            setState((currentState) => ({ ...currentState, ...nextState })),
        [setState]
    );

    const value: IAppStateContext = React.useMemo(
        () => ({ state, setState, updateState }),
        [setState, state, updateState]
    );

    return (
        <AppStateContext.Provider value={value}>
            {children}
        </AppStateContext.Provider>
    );
}
