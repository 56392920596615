import * as React from "react";

import { FC, ReactElement } from "react";
import { Flex } from "../../components/flex/Flex";
import { classMerge } from "../../utils/styleUtils";

const BASE_INPUT_CLASS_NAMES =
    "flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none";

const SPECIAL_INPUT_CLASS_NAMES =
    "ring-offset-background focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";
export interface IInputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {}

type TProps = React.ComponentPropsWithRef<"input">;

// React.ForwardRefExoticComponent<IInputProps & React.RefAttributes<HTMLInputElement>>
const Input = React.forwardRef<HTMLInputElement, IInputProps>(
    ({ className, type, ...props }, ref) => {
        return (
            <input
                type={type}
                className={classMerge(
                    BASE_INPUT_CLASS_NAMES,
                    SPECIAL_INPUT_CLASS_NAMES,
                    className
                )}
                ref={ref}
                {...props}
            />
        );
    }
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Input as any).displayName = "Input";

interface IDecoratedInputProps extends Omit<TProps, "prefix"> {
    prefix?: ReactElement | ReactElement[] | string | null;
    suffix?: ReactElement | ReactElement[] | string | null;
    prefixClassName?: string;
    suffixClassName?: string;
}

const DecoratedInput: FC<IDecoratedInputProps> = React.forwardRef<
    HTMLInputElement,
    IDecoratedInputProps
>(
    (
        {
            prefix,
            suffix,
            prefixClassName,
            suffixClassName,
            ...inputProps
        }: IDecoratedInputProps,
        ref
    ) => {
        const hasPrefix = !!prefix;
        const hasSuffix = !!suffix;

        return (
            <Flex
                alignItems={"items-center"}
                className={classMerge(
                    "rounded-md ring-offset-background focus-within:ring-2 focus-within:ring-theme-ring-primary focus-within:ring-offset-2",
                    {
                        "opacity-50 cursor-not-allowed": inputProps.disabled,
                    }
                )}
            >
                {prefix && (
                    <div
                        className={classMerge(
                            inputProps.className,
                            BASE_INPUT_CLASS_NAMES,
                            "rounded-none rounded-l-md border-r-0 w-auto",
                            prefixClassName
                        )}
                    >
                        {prefix}
                    </div>
                )}
                <Input
                    {...inputProps}
                    ref={ref}
                    className={classMerge(
                        inputProps.className,
                        "focus-visible:ring-0 focus-visible:ring-offset-0",
                        {
                            "rounded-none rounded-l-md":
                                hasSuffix && !hasPrefix,
                            "rounded-none": hasSuffix && hasPrefix,
                            "rounded-none rounded-r-md":
                                !hasSuffix && hasPrefix,
                        }
                    )}
                />
                {suffix && (
                    <div
                        className={classMerge(
                            inputProps.className,
                            BASE_INPUT_CLASS_NAMES,
                            "rounded-none rounded-r-md border-l-0 w-auto",
                            suffixClassName
                        )}
                    >
                        {suffix}
                    </div>
                )}
            </Flex>
        );
    }
);

export { DecoratedInput, Input, type IDecoratedInputProps };
