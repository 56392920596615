"use client";

import * as AvatarPrimitive from "@radix-ui/react-avatar";
import React, { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

/**
 * An image element with a fallback for representing the user.
 *
 * @see https://ui.shadcn.com/docs/components/avatar
 */
const Avatar: FC<React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>> =
    React.forwardRef<
        React.ElementRef<typeof AvatarPrimitive.Root>,
        React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
    >(({ className, ...props }, ref) => (
        <AvatarPrimitive.Root
            ref={ref}
            className={classMerge(
                "relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full border",
                className
            )}
            {...props}
        />
    ));
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage: FC<
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
> = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Image>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Image
        ref={ref}
        className={classMerge("aspect-square h-full w-full", className)}
        {...props}
    />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback: FC<
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
> = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={classMerge(
            "flex h-full w-full items-center justify-center rounded-full bg-muted",
            className
        )}
        {...props}
    />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const AvatarInitialsFallback: FC<
    Exclude<
        React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>,
        "children"
    > & { name: string }
> = React.forwardRef<
    React.ElementRef<typeof AvatarPrimitive.Fallback>,
    Exclude<
        React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>,
        "children"
    > & { name: string }
>(({ className, ...props }, ref) => (
    <AvatarPrimitive.Fallback
        ref={ref}
        className={classMerge(
            "flex h-full w-full items-center justify-center rounded-full bg-muted uppercase",
            className
        )}
        {...props}
    >
        {resolveInitials(props.name)}
    </AvatarPrimitive.Fallback>
));
AvatarInitialsFallback.displayName = AvatarPrimitive.Fallback.displayName;

/**
 * resolveInitials
 * Helper function to resolve the initials
 * @param name
 * @returns
 */
const resolveInitials = (name: string): string => {
    const splitted = name.split(" ");
    let initials = splitted[0][0];

    if (name) {
        if (splitted.length >= 2 && splitted[1]) {
            initials += splitted[1][0];
        } else if (splitted.length === 1 && name.length > 1) {
            initials += splitted[0][1];
        }
    } else {
        initials = "";
    }

    return initials;
};

export { Avatar, AvatarFallback, AvatarImage, AvatarInitialsFallback };
