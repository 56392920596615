/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { FC, useEffect } from "react";
import {
    Pie,
    PieChart as ReChartsDonutChart,
    ResponsiveContainer,
    Sector,
    Tooltip,
} from "recharts";
import { classMerge } from "../../utils/styleUtils";

import { parseData, parseLabelInput } from "./inputParser";

// import { CustomTooltipType } from "components/chart-elements/common/CustomTooltipProps";
import type { EventProps } from "../common/types";
import { BaseAnimationTimingProps } from "../common/types";
import { DonutChartTooltip } from "./DonutChartTooltip";

type DonutChartVariant = "donut" | "pie";

export interface IDonutChartProps extends BaseAnimationTimingProps {
    data: any[];
    category?: string;
    index?: string;
    colors?: string[];
    variant?: DonutChartVariant;
    valueFormatter?: (value: any) => number;
    label?: string;
    showLabel?: boolean;
    showAnimation?: boolean;
    showTooltip?: boolean;
    noDataText?: string;
    className?: string;
    onValueChange?: (value: EventProps) => void;
    // customTooltip?: React.ComponentType<CustomTooltipType>;
}

const renderInactiveShape = (props: any) => {
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        className,
    } = props;

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                className={className}
                fill=""
                opacity={0.3}
                style={{ outline: "none" }}
            />
        </g>
    );
};

export const DonutChart: FC<IDonutChartProps> = React.forwardRef<
    HTMLDivElement,
    IDonutChartProps
>((props, ref) => {
    const {
        data = [],
        category = "value",
        index = "name",
        colors = [],
        variant = "donut",
        valueFormatter = (value: any) => value,
        label,
        showLabel = true,
        animationDuration = 900,
        showAnimation = false,
        showTooltip = true,
        noDataText,
        onValueChange,
        // customTooltip,
        className,
        ...other
    } = props;
    // const CustomTooltip = customTooltip;
    const isDonut = variant == "donut";
    const parsedLabelInput = parseLabelInput(
        label,
        valueFormatter,
        data,
        category
    );

    const [activeIndex, setActiveIndex] = React.useState<number | undefined>(
        undefined
    );
    const hasOnValueChange = !!onValueChange;

    function onShapeClick(data: any, index: number, event: React.MouseEvent) {
        event.stopPropagation();

        if (!hasOnValueChange) return;
        if (activeIndex === index) {
            setActiveIndex(undefined);
            onValueChange?.(null);
        } else {
            setActiveIndex(index);
            onValueChange?.({
                eventType: "slice",
                ...data.payload.payload,
            });
        }
    }

    useEffect(() => {
        const pieSectors = document.querySelectorAll(".recharts-pie-sector");
        if (pieSectors) {
            pieSectors.forEach((sector) => {
                sector.setAttribute("style", "outline: none");
            });
        }
    }, [activeIndex]);

    return (
        <div
            ref={ref}
            className={classMerge("w-full h-40", className)}
            {...other}
        >
            <ResponsiveContainer className="h-full w-full">
                {data?.length ? (
                    <ReChartsDonutChart
                        onClick={
                            hasOnValueChange && activeIndex
                                ? () => {
                                      setActiveIndex(undefined);
                                      onValueChange?.(null);
                                  }
                                : undefined
                        }
                        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    >
                        {showLabel && isDonut ? (
                            <text
                                className={classMerge(
                                    "text-secondary-foreground text-3xl font-bold"
                                )}
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                            >
                                {parsedLabelInput}
                            </text>
                        ) : null}
                        <Pie
                            className={classMerge(
                                "",
                                onValueChange
                                    ? "cursor-pointer"
                                    : "cursor-default"
                            )}
                            data={parseData(data, colors)}
                            cx="50%"
                            cy="50%"
                            startAngle={90}
                            endAngle={-270}
                            innerRadius={isDonut ? "75%" : "0%"}
                            outerRadius="100%"
                            stroke=""
                            strokeLinejoin="round"
                            dataKey={category}
                            nameKey={index}
                            isAnimationActive={showAnimation}
                            animationDuration={animationDuration}
                            onClick={onShapeClick}
                            activeIndex={activeIndex}
                            inactiveShape={renderInactiveShape}
                            style={{ outline: "none" }}
                        />
                        {showTooltip ? (
                            <Tooltip
                                wrapperStyle={{ outline: "none" }}
                                isAnimationActive={true}
                                content={({ active, payload }) => (
                                    <DonutChartTooltip
                                        active={active}
                                        payload={payload}
                                        // valueFormatter={valueFormatter}
                                    />
                                )}
                            />
                        ) : null}
                        {/* <Tooltip
                                wrapperStyle={{ outline: "none" }}
                                isAnimationActive={false}
                                content={
                                    showTooltip ? (
                                        ({ active, payload }) =>
                                            CustomTooltip ? (
                                                <CustomTooltip
                                                    payload={payload?.map(
                                                        (payloadItem) => ({
                                                            ...payloadItem,
                                                            color:
                                                                payload?.[0]
                                                                    ?.payload
                                                                    ?.color ??
                                                                BaseColors.Gray,
                                                        })
                                                    )}
                                                    active={active}
                                                    label={payload?.[0]?.name}
                                                />
                                            ) : (
                                                <DonutChartTooltip
                                                    active={active}
                                                    payload={payload}
                                                    // valueFormatter={
                                                    //     valueFormatter
                                                    // }
                                                />
                                            )
                                    ) : (
                                        <></>
                                    )
                                }
                            /> */}
                    </ReChartsDonutChart>
                ) : (
                    <div>{noDataText}</div>
                )}
            </ResponsiveContainer>
        </div>
    );
});

DonutChart.displayName = "DonutChart";
