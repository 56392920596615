"use client";

import * as LabelPrimitive from "@radix-ui/react-label";
import { type VariantProps } from "class-variance-authority";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";
import { labelVariants } from "./labelVariants";

export type TLabelProps = React.ComponentPropsWithoutRef<
    typeof LabelPrimitive.Root
> &
    VariantProps<typeof labelVariants>;

const Label: FC<TLabelProps> = React.forwardRef<
    React.ElementRef<typeof LabelPrimitive.Root>,
    TLabelProps
>(({ className, ...props }, ref) => (
    <LabelPrimitive.Root
        ref={ref}
        className={classMerge(labelVariants(), className)}
        {...props}
    />
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
