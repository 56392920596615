import { ValidationEngine as FpValidationEngine } from "@hotelchamp/common";
import { i18next } from "../services/i18n";

export const ValidationEngine = FpValidationEngine;

ValidationEngine.registerValidatorFn([]);

ValidationEngine.setClassMessageOverrides({
    required: () => i18next.t("common:validate.is-required"),
    minLength: (props: any) =>
        i18next.t("common:validate.min-length", {
            value: props[1],
        }),
    maxLength: (props: any) =>
        i18next.t("common:validate.max-length", {
            value: props[1],
        }),
    email: (props: any) =>
        i18next.t("common:validate.email", {
            value: props[1],
        }),
});
