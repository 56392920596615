import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
    Button,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
    Switch,
} from "@worck/ui";

export const CompanyIntegrationOverviewPage = () => {
    const integrations = [
        {
            name: "Homerun",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Application Tracking System",
            isIntegrated: false,
            logo: "https://worck.test/logos/homerun.png",
        },
        {
            name: "TestGorrila",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Assestment",
            isIntegrated: false,
            logo: "https://worck.test/logos/testgorilla.svg",
        },
        {
            name: "Recruitee",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Application Tracking System",
            isIntegrated: true,
            logo: "https://worck.test/logos/recruitee.png",
        },
        {
            name: "AFAS",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "HRIS",
            isIntegrated: true,
            logo: "https://worck.test/logos/afas.png",
        },
        {
            name: "BambooHR",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "HRIS",
            isIntegrated: false,
            logo: "https://worck.test/logos/bamboohr.svg",
        },
        {
            name: "Homerun",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Application Tracking System",
            isIntegrated: false,
            logo: "https://worck.test/logos/homerun.png",
        },
        {
            name: "TestGorrila",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Assestment",
            isIntegrated: false,
            logo: "https://worck.test/logos/testgorilla.svg",
        },
        {
            name: "Recruitee",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "Application Tracking System",
            isIntegrated: true,
            logo: "https://worck.test/logos/recruitee.png",
        },
        {
            name: "AFAS",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "HRIS",
            isIntegrated: true,
            logo: "https://worck.test/logos/afas.png",
        },
        {
            name: "BambooHR",
            description:
                "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium",
            type: "HRIS",
            isIntegrated: false,
            logo: "https://worck.test/logos/bamboohr.svg",
        },
    ];
    return (
        <div className="flex">
            <div>
                <div className="w-1/2 flex-col justify-start items-start gap-5 inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <div className="self-stretch text-secondary-foreground text-3xl font-semibold leading-none">
                            Integrations
                        </div>
                        <p className="text-base font-normal text-secondary-foreground/50">
                            All our integrations parnters at one place.
                        </p>
                    </div>
                </div>

                <div>
                    <div className="flex items-center py-5">
                        <p className="text-secondary-foreground text-lg font-semibold">
                            Power your setup with great integrations
                        </p>

                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button variant="outline" className="ml-auto">
                                    Columns{" "}
                                    <ChevronDownIcon className="ml-2 h-4 w-4" />
                                </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                                {/* {table
                                .getAllColumns()
                                .filter((column) => column.getCanHide())
                                .map((column) => {
                                    return (
                                        <DropdownMenuCheckboxItem
                                            key={column.id}
                                            className="capitalize"
                                            checked={column.getIsVisible()}
                                            onCheckedChange={(value) =>
                                                column.toggleVisibility(!!value)
                                            }
                                        >
                                            {column.id}
                                        </DropdownMenuCheckboxItem>
                                    );
                                })} */}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                    <div className="grid grid-cols-3 gap-8">
                        {integrations.map((integration) => (
                            <div className="border col-span-1 rounded-md flex flex-col justify-between">
                                <div className="px-6 pt-6 pb-2 ">
                                    <img
                                        src={integration.logo}
                                        alt=""
                                        className="w-28 mb-4"
                                    />
                                    <h4 className="text-base font-semibold text-secondary-foreground">
                                        {integration.name}
                                    </h4>
                                    <p className="text-sm text-secondary-foreground/40 mb-4">
                                        {integration.type}
                                    </p>
                                    <p className="text-sm text-secondary-foreground/50">
                                        {integration.description}
                                    </p>
                                </div>
                                <div>
                                    <div className="border-t border-secondary-foreground/10 my-4" />
                                    <div className="text-right px-3 pb-3">
                                        <Switch className="" />
                                        {/* <Button variant="outline">
                                            Upgrade seats
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
