import { config } from "../config";
import { i18next } from "./i18n";

const language = i18next.language || "en_GB";

export const facebookReady = new Promise((resolve) => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
        window.FB.init({
            appId: config.facebookPublisherAppId,
            cookie: true,
            xfbml: true,
            version: "v18.0",
        });

        // auto authenticate with the api if already logged in with facebook
        window.FB.getLoginStatus(({ authResponse }) => {
            // if (authResponse) {
            //     accountService.apiAuthenticate(authResponse.accessToken).then(resolve);
            // } else {
            //     resolve();
            // }
        });

        resolve(window.FB);
    };

    // load facebook sdk script
    (function (doc, tag, id) {
        let js;

        const fjs = doc.getElementsByTagName(tag)[0];

        if (doc.getElementById(id)) {
            return;
        }

        js = doc.createElement(tag) as HTMLScriptElement;
        js.id = id;
        js.src = `https://connect.facebook.net/${language}/sdk.js`;

        fjs?.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
});
