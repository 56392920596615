import {
    DepartmentSelect,
    departmentTypes,
    type IDepartmentSelectProps,
} from "@app/department";
import React from "react";
import { useGetCompanyDepartmentItems } from "../hooks";

const EMPTY_DEPARTMENTS: departmentTypes.IDepartment[] = [];

export interface ICompanyDepartmentSelectProps extends IDepartmentSelectProps {
    companyId: string;
}

export const CompanyDepartmentSelect = React.forwardRef(
    (
        { companyId, ...selectFieldProps }: ICompanyDepartmentSelectProps,
        ref: any
    ) => {
        const { data: paginatedCompanyDepartmentsResponse } =
            useGetCompanyDepartmentItems(companyId, 1);
        const companyDepartments =
            paginatedCompanyDepartmentsResponse?.data || EMPTY_DEPARTMENTS;

        return (
            <DepartmentSelect
                options={companyDepartments}
                {...selectFieldProps}
                ref={ref}
            />
        );
    }
);
