import { BoxedSpinner, Flex } from "@worck/ui";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks";

export const LogoutPage = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.hasAuthenticatedSession) {
            auth.logout().then(() => {
                // Check logout: doesn't seem to work always reliable...

                clearListCookies();

                // required to clear cookies. Otherwise session will be extended
                location.reload();
            });
        } else {
            navigate("/");
        }
    }, []);

    return (
        <Flex justifyContent={"justify-center"}>
            <BoxedSpinner />
        </Flex>
    );
};

function clearListCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var spcook = cookies[i].split("=");
        document.cookie =
            spcook[0] + "=;expires=Thu, 21 Sep 1979 00:00:01 UTC;";
    }
}
