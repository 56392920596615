import i18nextLibrary from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18nextLibrary.init({
    fallbackLng: "nl",
    debug: true,

    resources: {
        en: {
            common: {
                navigate: {
                    back: "Back",
                },
                text: { "not-allowed": "Not allowed" },
                search: "Search",
                validate: {
                    "is-required": "Is required",
                    required: "Is required",
                    "min-length": "Is min length of {{value}}",
                    "max-length": "Is max length of {{value}}",
                    min: "Is min {{value}}",
                    max: "Is max {{value}}",
                    present: "Is present",
                    email: "Is valid email address",
                },
            },
            app: {
                topBar: { appTitle: "Job portal" },
            },
            auth: {
                signUpInByEmailForm: {
                    buttonContinueLabel: "Continue",
                },
            },
            login: {
                Signin: "sign-in",
                email: "E-mail",
                password: "Password",
                "or-continue-with": "Or continue with",
            },
            vacancy: {
                "get-notification":
                    "Get notification about changes of this vacancy.",
                vacancyGridCard: {
                    postedBy: "Posted by",
                    hiringManagerAt: "Hiring manager at {{company}}",
                    postedOn: "Posted on",
                    department: "Unit/ Team",
                    workArea: "Field",
                    content: {
                        header: "About the job opening",
                        previewLinkText: "View full job posting",
                        shareButtonLabel: "Share job posting",
                        bonusApply: "When applying",
                        appointment: "Interview",
                    },
                },
                vacancyShareDialog: {
                    header: "Just one more step away from earning!",
                    headerCustomPersonalBonus:
                        "Just one step away from entering for your personal experience!",
                    subTitle:
                        "We offer a reward for applicants from your network: <strong>€{{matchingBonus}},-</strong>",
                    subTitleHigherBonus:
                        "We offer a substantial reward for applicants from your network: <strong>€{{matchingBonus}},-</strong>",
                    subTitleCustomPersonalBonus:
                        "We offer a special reward for applicants from your network!",
                    sharePreviewContainer: {
                        defaultMessage: "Interesting job at {{company}}!",
                        title: "This is how your post will appear on {{deliveryMethod}}",
                        warningWasSharedToday:
                            "You've already shared this job posting on {{deliveryMethod}} today",
                        buttonShareLabel:
                            "Share job posting via {{deliveryMethod}}",
                        linkedIn: {
                            linkTitle:
                                "Job opening {{vacancyTitle}} at {{company}} | Want to learn more or apply directly?",
                        },
                    },
                },
            },
            publisher: {
                publisherSignInPage: {
                    stepSignup: {
                        name: "Sign in",
                        description: "15 seconds",
                    },
                    stepShare: {
                        name: "Share vacancy",
                        description: "60 seconds",
                    },
                    stepVerify: {
                        name: "Verify",
                        description: "",
                    },
                    content: {
                        header: "Could you please spare a minute?",
                        intro: "By sharing one of our job openings on LinkedIn, you can qualify for a referral bonus of €{{ max_matching_bonus }}!",
                        introNoMatchingBonus:
                            'By sharing one of our job openings on LinkedIn, you can qualify for a special referral incentive, "An Experience Tailored To Your Needs"',
                        remarkBusinessEmail: "Use your business email address",
                        verifyEmailSentText:
                            "An email with a verification code has been sent to {{email}}",
                    },
                },
            },
        },
        nl: {
            common: {
                navigate: {
                    back: "Terug",
                },
                text: { "not-allowed": "Geen toegang" },
                search: "Zoeken",
                validate: {
                    "is-required": "Is verplicht",
                    required: "Is verplicht",
                    "min-length": "Heeft minimale lengte van {{value}}",
                    "max-length": "Heeft maximale lengte van {{value}}",
                    min: "Is min {{value}}",
                    max: "Is max {{value}}",
                    present: "Is aanwezig",
                    email: "Is een geldig emailadres",
                },
            },
            app: {
                topBar: { appTitle: "Vacature portaal" },
            },
            auth: {
                signUpInByEmailForm: {
                    buttonContinueLabel: "Verder",
                },
            },
            login: {
                Signin: "Inloggen",
                email: "E-mail",
                password: "Wachtwoord",
                "or-continue-with": "Of login via",
            },
            vacancy: {
                "get-notification":
                    "Ontvang notificaties wanneer er iets wijzigd voor deze vacature.",
                vacancyGridCard: {
                    postedBy: "Geplaatst door",
                    hiringManagerAt: "Hiring manager bij {{company}}",
                    postedOn: "Geplaatst op",
                    department: "Afdeling",
                    workArea: "Werkgebied",
                    content: {
                        header: "Over de vacature",
                        previewLinkText: "Volledige vacature bekijken",
                        shareButtonLabel: "Deel vacature",
                        bonusApply: "Bij sollicitatie",
                        appointment: "Aanstelling",
                    },
                },
                vacancyShareDialog: {
                    header: "Nog 1 stap verwijderd om te verdienen!",
                    headerCustomPersonalBonus:
                        "Nog 1 stap verwijderd om mee te dingen naar jouw persoonlijk experience!",
                    subTitle:
                        "We geven een beloning voor sollicitanten uit jouw netwerk: <strong>€{{matchingBonus}},-</strong>",
                    subTitleHigherBonus:
                        "We geven een serieuze beloning voor sollicitanten uit jouw netwerk: <strong>€{{matchingBonus}},-</strong>",
                    subTitleCustomPersonalBonus:
                        "We geven een bijzondere beloning voor sollicitanten uit jouw netwerk!",
                    sharePreviewContainer: {
                        defaultMessage:
                            "Ik kan me zomaar voorstellen deze professional zich in mijn netwerk bevindt: ",
                        title: "Zo komt jouw bericht eruit te zien op {{deliveryMethod}}",
                        warningWasSharedToday:
                            "Deze vacature heb je vandaag al op {{deliveryMethod}} gedeeld.",
                        buttonShareLabel:
                            "Deel vacature via {{deliveryMethod}}",
                        linkedIn: {
                            linkTitle:
                                "Vacature {{vacancyTitle}} bij {{company}} | meer weten of direct solliciteren?",
                        },
                    },
                },
            },
            publisher: {
                publisherSignInPage: {
                    stepSignup: {
                        name: "Aanmelden",
                        description: "15 seconden",
                    },
                    stepShare: {
                        name: "Vacature delen",
                        description: "60 seconden",
                    },
                    stepVerify: {
                        name: "Verifiëren",
                        description: "",
                    },
                    content: {
                        header: "Heb je één minuut voor mij?",
                        intro: "Deel één van onze vacatures op LinkedIn en kom in aanmerking voor een aanbrengbonus tot € {{ max_matching_bonus }} bruto!",
                        introNoMatchingBonus:
                            'Door een van onze vacatures op LinkedIn te delen, kun je in aanmerking komen voor een speciale doorverwijzingsbonus, "Een ervaring op maat voor jou"',
                        remarkBusinessEmail:
                            "Gebruik je zakelijk e-mail adress",
                        verifyEmailSentText:
                            "Er is een e-mail met verificatiecode verstuurd naar {{email}}",
                    },
                },
            },
        },
    },
});

export const i18next = i18nextLibrary;
