import { type VariantProps } from "class-variance-authority";
import React from "react";
import { classMerge } from "../../utils/styleUtils";
import { headingVariants } from "./headingVariants";

export interface IHeadingProps
    extends React.HTMLAttributes<HTMLHeadingElement>,
        VariantProps<typeof headingVariants> {}

export const Heading: React.FC<IHeadingProps> = React.forwardRef<
    HTMLHeadingElement,
    IHeadingProps
>((props, ref) => {
    const { className, children, importance } = props;
    const Cmp = !importance ? "h1" : importance;

    return (
        <Cmp
            ref={ref}
            className={classMerge(headingVariants({ importance, className }))}
        >
            {children}
        </Cmp>
    );
});

Heading.displayName = "Heading";
