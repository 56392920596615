import { cva } from "class-variance-authority";

export const headingVariants = cva("", {
    variants: {
        importance: {
            h1: "text-2xl",
            h2: "text-xl",
            h3: "text-lg",
            h4: "text-base",
            h5: "text-sm",
            h6: "text-xs",
        },
    },
    defaultVariants: {
        importance: "h1",
    },
});
