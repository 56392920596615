import { commonTypes, utilsQuery } from "@app/common";
import { useMutation, useQuery } from "react-query";
import { createVacancyTip, getLead, getTipsterVacancy } from "./queries";
import { ILead, ITipsterVacancy } from "./types";

const define = utilsQuery.defineQueryKeyCreator("lead");

export const QueryKeys = {
    CompanyLeadItems: define("company-lead-items"),
    LeadItem: define("lead-item"),
    TipsterVacancy: define("tipster-vacancy"),
} as const;

export const useGetLead = (
    leadId?: string,
    options?: commonTypes.IUseGetOptions
) =>
    useQuery<ILead, Error>(
        [QueryKeys.LeadItem, leadId],
        () => getLead(leadId),
        options
    );

export const useGetTipsterVacancy = (
    tipsterVacancyId: string,
    options: commonTypes.IUseGetOptions
) =>
    useQuery<ITipsterVacancy, Error>(
        [QueryKeys.TipsterVacancy, tipsterVacancyId],
        () => getTipsterVacancy(tipsterVacancyId),
        options
    );

export const useCreateVacancyTip = (tipsterVacancyId: string) =>
    useMutation((data: any) => createVacancyTip(tipsterVacancyId, data));
