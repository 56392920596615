import { authTypes } from "@app/auth";
import { Dialog, Transition } from "@headlessui/react";
import {
    Bars3Icon,
    ChartPieIcon,
    Cog6ToothIcon,
    FolderIcon,
    HomeIcon,
    LifebuoyIcon,
    UserIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import {
    Avatar,
    AvatarImage,
    AvatarInitialsFallback,
    Tooltip,
    TooltipArrow,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@worck/ui";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

export interface ISidebarProps {
    authenticatedUser: authTypes.IAuthenticatedUser;
    className?: string;
}

export const Sidebar = ({ authenticatedUser, className }: ISidebarProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const navigation = [
        { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
        {
            name: "Publishers",
            href: "/companies/leads",
            icon: UserIcon,
            current: false,
        },
        {
            name: "Vacatures",
            href: "/companies/vacancies",
            icon: FolderIcon,
            current: false,
        },
        // { name: "Calendar", href: "#", icon: CalendarIcon, current: false },
        // {
        //     name: "Documents",
        //     href: "/companies/vacancies",
        //     icon: DocumentDuplicateIcon,
        //     current: false,
        // },
        {
            name: "Reports",
            href: "/companies/reports",
            icon: ChartPieIcon,
            current: false,
        },
    ];

    const bottomNavigation = [
        {
            name: "Settings",
            href: "/settings",
            icon: Cog6ToothIcon,
            current: true,
        },
        { name: "Help", href: "#", icon: LifebuoyIcon, current: false },
    ];

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(" ");
    }

    return (
        <Fragment>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-50 lg:hidden"
                    onClose={setSidebarOpen}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button
                                            type="button"
                                            className="-m-2.5 p-2.5"
                                            onClick={() =>
                                                setSidebarOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                Close sidebar
                                            </span>
                                            <XMarkIcon
                                                className="h-6 w-6 text-white"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    </div>
                                </Transition.Child>

                                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                                    <div className="flex h-16 shrink-0 items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="60"
                                            height="58"
                                            viewBox="0 0 60 58"
                                            fill="none"
                                        >
                                            <path
                                                d="M59.97 11.28L46.75 54.3001L29.04 32.2601L22.28 57.56L0 27.6901L2.64999 15.5101L17.6 34.8401L24.13 8.59005L41.57 29.42L49.9 0.300049L59.97 11.28Z"
                                                fill="white"
                                            />
                                        </svg>
                                    </div>

                                    <nav className="flex flex-1 flex-col">
                                        <ul
                                            role="list"
                                            className="-mx-2 flex-1 space-y-1"
                                        >
                                            {navigation.map((item) => (
                                                <li key={item.name}>
                                                    <Link
                                                        to={item.href}
                                                        className={classNames(
                                                            item.current
                                                                ? "bg-gray-800 text-white"
                                                                : "text-gray-400 hover:text-white hover:bg-gray-800",
                                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                                        )}
                                                    >
                                                        <item.icon
                                                            className="h-6 w-6 shrink-0"
                                                            aria-hidden="true"
                                                        />
                                                        {item.name}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </nav>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden bg-white lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:pb-4 border-r">
                <div className="flex flex-col justify-between grow overflow-y-auto h-full items-center">
                    <div>
                        <div className="flex mt-8 mb-6 shrink-0 items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="37"
                                height="36"
                                viewBox="0 0 37 36"
                                fill="none"
                            >
                                <path
                                    d="M7.77169 9.32119L10.3218 12.4736L11.6624 7.09168L18.5889 5.92593L22.1882 10.0304L24.1846 3.06011L27.5264 2.51609C24.8452 0.918033 21.717 0 18.3752 0C11.2107 0 5.03702 4.21129 2.1712 10.2878L7.77655 9.32119H7.77169Z"
                                    fill="#672A61"
                                />
                                <path
                                    d="M29.7073 27.6187L22.6545 29.2896L18.8269 24.5246L17.4426 29.3188L10.7735 30.8731L0.485711 17.0832C0.47114 17.36 0.466278 17.6369 0.466278 17.9138C0.466278 27.8081 8.48571 35.8276 18.3801 35.8276C28.2744 35.8276 36.2938 27.8081 36.2938 17.9138C36.2938 15.6163 35.8567 13.4208 35.0698 11.405L29.717 27.6235L29.7073 27.6187Z"
                                    fill="#672A61"
                                />
                                <path
                                    d="M24.1797 3.06013L29.071 8.39347L22.6497 29.2896L29.7073 27.6187L36.493 7.05285L31.5191 1.86523L24.1797 3.06013Z"
                                    fill="#41B6E6"
                                />
                                <path
                                    d="M22.1882 10.0304L18.5889 5.92596L11.6624 7.08686L20.1336 17.2047L22.1882 10.0304Z"
                                    fill="#41B6E6"
                                />
                                <path
                                    d="M14.0474 18.5841L18.8221 24.5246L17.4378 29.3188L10.7638 30.8731L14.0474 18.5841Z"
                                    fill="#41B6E6"
                                />
                                <path
                                    d="M8.49061 19.8373L10.3218 12.4736L7.77172 9.32123L1.22891 10.4481L8.49061 19.8373Z"
                                    fill="#41B6E6"
                                />
                            </svg>
                        </div>
                        <nav>
                            <ul
                                role="list"
                                className="flex flex-col items-center space-y-1"
                            >
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            className={classNames(
                                                "hover:text-theme-primary hover:bg-muted",
                                                item.current
                                                    ? "text-[#344054] bg-[#F9FAFB]"
                                                    : "text-foreground/70",
                                                "group flex rounded-md text-sm leading-6 font-semibold"
                                            )}
                                        >
                                            <TooltipProvider>
                                                <Tooltip delayDuration={0}>
                                                    <TooltipTrigger className="p-3">
                                                        <item.icon
                                                            className="h-6 w-6 shrink-0"
                                                            aria-hidden="true"
                                                        />
                                                        <span className="sr-only">
                                                            {item.name}
                                                        </span>
                                                    </TooltipTrigger>
                                                    <TooltipContent
                                                        side="right"
                                                        sideOffset={20}
                                                        className="bg-foreground/90 text-white font-normal"
                                                    >
                                                        {item.name}
                                                        <TooltipArrow className="fill-foregound opacity-70" />
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className="flex flex-col items-center gap-y-8">
                        <ul
                            role="list"
                            className="flex flex-col items-center space-y-1"
                        >
                            {bottomNavigation.map((item) => (
                                <li key={item.name}>
                                    <Link
                                        to={item.href}
                                        className={classNames(
                                            "hover:text-theme-primary hover:bg-muted",
                                            item.current
                                                ? "text-[#344054] bg-[#F9FAFB]"
                                                : "text-foreground/70",
                                            "group flex rounded-md text-sm leading-6 font-semibold"
                                        )}
                                    >
                                        <TooltipProvider>
                                            <Tooltip delayDuration={0}>
                                                <TooltipTrigger className="p-3">
                                                    <item.icon
                                                        className="h-6 w-6 shrink-0"
                                                        aria-hidden="true"
                                                    />
                                                    <span className="sr-only">
                                                        {item.name}
                                                    </span>
                                                </TooltipTrigger>
                                                <TooltipContent
                                                    side="right"
                                                    sideOffset={20}
                                                    className="bg-foreground/90 text-white font-normal"
                                                >
                                                    {item.name}
                                                    <TooltipArrow className="fill-foregound opacity-70" />
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </Link>
                                </li>
                            ))}
                        </ul>

                        <a href="#">
                            <span className="sr-only">Your profile</span>
                            <Avatar className="h-10 w-10">
                                <AvatarImage
                                    src={authenticatedUser.avatar?.original_url}
                                    alt="Avatar"
                                />
                                <AvatarInitialsFallback
                                    name={
                                        authenticatedUser.name ||
                                        authenticatedUser.email
                                    }
                                />
                            </Avatar>
                        </a>
                    </div>
                </div>
            </div>

            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
                <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className="flex-1 text-sm font-semibold leading-6 text-white">
                    Dashboard
                </div>
                <a href="#">
                    <span className="sr-only">Your profile</span>
                    <Avatar className="h-10 w-10">
                        <AvatarImage
                            src={authenticatedUser.avatar?.original_url}
                            alt="Avatar"
                        />
                        <AvatarInitialsFallback
                            name={
                                authenticatedUser.name ||
                                authenticatedUser.email
                            }
                        />
                    </Avatar>
                </a>
            </div>
        </Fragment>
    );
};
