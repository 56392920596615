"use client";

import * as ContextMenuPrimitive from "@radix-ui/react-context-menu";
import { Check, ChevronRight, Circle } from "lucide-react";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

const ContextMenu = ContextMenuPrimitive.Root;

const ContextMenuTrigger = ContextMenuPrimitive.Trigger;

const ContextMenuGroup = ContextMenuPrimitive.Group;

const ContextMenuPortal = ContextMenuPrimitive.Portal;

const ContextMenuSub = ContextMenuPrimitive.Sub;

const ContextMenuRadioGroup = ContextMenuPrimitive.RadioGroup;

export type TContextMenuSubTriggerProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.SubTrigger
> & {
    inset?: boolean;
};

const ContextMenuSubTrigger: FC<TContextMenuSubTriggerProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.SubTrigger>,
    TContextMenuSubTriggerProps
>(({ className, inset, children, ...props }, ref) => (
    <ContextMenuPrimitive.SubTrigger
        ref={ref}
        className={classMerge(
            "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground",
            inset && "pl-8",
            className
        )}
        {...props}
    >
        {children}
        <ChevronRight className="ml-auto h-4 w-4" />
    </ContextMenuPrimitive.SubTrigger>
));
ContextMenuSubTrigger.displayName = ContextMenuPrimitive.SubTrigger.displayName;

export type TContextMenuSubContentProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.SubContent
>;

const ContextMenuSubContent: FC<TContextMenuSubContentProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.SubContent>,
    TContextMenuSubContentProps
>(({ className, ...props }, ref) => (
    <ContextMenuPrimitive.SubContent
        ref={ref}
        className={classMerge(
            "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className
        )}
        {...props}
    />
));
ContextMenuSubContent.displayName = ContextMenuPrimitive.SubContent.displayName;

export type TContextMenuContentProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.Content
>;

const ContextMenuContent: FC<TContextMenuContentProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Content>,
    TContextMenuContentProps
>(({ className, ...props }, ref) => (
    <ContextMenuPrimitive.Portal>
        <ContextMenuPrimitive.Content
            ref={ref}
            className={classMerge(
                "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md animate-in fade-in-80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                className
            )}
            {...props}
        />
    </ContextMenuPrimitive.Portal>
));
ContextMenuContent.displayName = ContextMenuPrimitive.Content.displayName;

export type TContextMenuItemProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.Item
> & {
    inset?: boolean;
};

const ContextMenuItem: FC<TContextMenuItemProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Item>,
    TContextMenuItemProps
>(({ className, inset, ...props }, ref) => (
    <ContextMenuPrimitive.Item
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            inset && "pl-8",
            className
        )}
        {...props}
    />
));
ContextMenuItem.displayName = ContextMenuPrimitive.Item.displayName;

export type TContextMenuCheckboxItemProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.CheckboxItem
>;

const ContextMenuCheckboxItem: FC<TContextMenuCheckboxItemProps> =
    React.forwardRef<
        React.ElementRef<typeof ContextMenuPrimitive.CheckboxItem>,
        TContextMenuCheckboxItemProps
    >(({ className, children, checked, ...props }, ref) => (
        <ContextMenuPrimitive.CheckboxItem
            ref={ref}
            className={classMerge(
                "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
                className
            )}
            checked={checked}
            {...props}
        >
            <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
                <ContextMenuPrimitive.ItemIndicator>
                    <Check className="h-4 w-4" />
                </ContextMenuPrimitive.ItemIndicator>
            </span>
            {children}
        </ContextMenuPrimitive.CheckboxItem>
    ));
ContextMenuCheckboxItem.displayName =
    ContextMenuPrimitive.CheckboxItem.displayName;

export type TContextMenuRadioItemProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.RadioItem
>;

const ContextMenuRadioItem: FC<TContextMenuRadioItemProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.RadioItem>,
    TContextMenuRadioItemProps
>(({ className, children, ...props }, ref) => (
    <ContextMenuPrimitive.RadioItem
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <ContextMenuPrimitive.ItemIndicator>
                <Circle className="h-2 w-2 fill-current" />
            </ContextMenuPrimitive.ItemIndicator>
        </span>
        {children}
    </ContextMenuPrimitive.RadioItem>
));
ContextMenuRadioItem.displayName = ContextMenuPrimitive.RadioItem.displayName;

export type TContextMenuLabelProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.Label
> & {
    inset?: boolean;
};

const ContextMenuLabel: FC<TContextMenuLabelProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Label>,
    TContextMenuLabelProps
>(({ className, inset, ...props }, ref) => (
    <ContextMenuPrimitive.Label
        ref={ref}
        className={classMerge(
            "px-2 py-1.5 text-sm font-semibold text-foreground",
            inset && "pl-8",
            className
        )}
        {...props}
    />
));
ContextMenuLabel.displayName = ContextMenuPrimitive.Label.displayName;

export type TContextMenuSeparatorProps = React.ComponentPropsWithoutRef<
    typeof ContextMenuPrimitive.Separator
>;

const ContextMenuSeparator: FC<TContextMenuSeparatorProps> = React.forwardRef<
    React.ElementRef<typeof ContextMenuPrimitive.Separator>,
    TContextMenuSeparatorProps
>(({ className, ...props }, ref) => (
    <ContextMenuPrimitive.Separator
        ref={ref}
        className={classMerge("-mx-1 my-1 h-px bg-border", className)}
        {...props}
    />
));
ContextMenuSeparator.displayName = ContextMenuPrimitive.Separator.displayName;

const ContextMenuShortcut = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            className={classMerge(
                "ml-auto text-xs tracking-widest text-muted-foreground",
                className
            )}
            {...props}
        />
    );
};
ContextMenuShortcut.displayName = "ContextMenuShortcut";

export {
    ContextMenu,
    ContextMenuCheckboxItem,
    ContextMenuContent,
    ContextMenuGroup,
    ContextMenuItem,
    ContextMenuLabel,
    ContextMenuPortal,
    ContextMenuRadioGroup,
    ContextMenuRadioItem,
    ContextMenuSeparator,
    ContextMenuShortcut,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
};
