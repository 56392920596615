"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipArrow = TooltipPrimitive.TooltipArrow;

const TooltipTrigger = TooltipPrimitive.Trigger;

export type TTooltipContentProps = React.ComponentPropsWithoutRef<
    typeof TooltipPrimitive.Content
>;

const TooltipContent: FC<TTooltipContentProps> = React.forwardRef<
    React.ElementRef<typeof TooltipPrimitive.Content>,
    TTooltipContentProps
>(({ className, sideOffset = 4, children, ...props }, ref) => (
    <TooltipPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        className={classMerge(
            "data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-theme-secondary select-none rounded-[4px] bg-secondary-foreground px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]",
            className
        )}
        {...props}
    >
        {children}
    </TooltipPrimitive.Content>
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export {
    Tooltip,
    TooltipArrow,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
};
