"use client";

import * as SwitchPrimitives from "@radix-ui/react-switch";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TSwitchProps = React.ComponentPropsWithoutRef<
    typeof SwitchPrimitives.Root
>;

const Switch: FC<TSwitchProps> = React.forwardRef<
    React.ElementRef<typeof SwitchPrimitives.Root>,
    TSwitchProps
>(({ className, ...props }, ref) => (
    <SwitchPrimitives.Root
        className={classMerge(
            "peer inline-flex h-[24px] w-[44px] shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-teal-700 data-[state=unchecked]:bg-input",
            className
        )}
        {...props}
        ref={ref}
    >
        <SwitchPrimitives.Thumb
            className={classMerge(
                "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
            )}
        />
    </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
