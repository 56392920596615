import * as React from "react";
import { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export interface ISubTitleProps
    extends React.TextareaHTMLAttributes<HTMLParagraphElement> {}

const SubTitle: FC<ISubTitleProps> = React.forwardRef<
    HTMLParagraphElement,
    ISubTitleProps
>(({ className, ...props }, ref) => {
    return (
        <p
            className={classMerge("text-lg leading-8 text-gray-600", className)}
            ref={ref}
            {...props}
        />
    );
});

SubTitle.displayName = "SubTitle";

export { SubTitle };
