import { queryClient } from "@app/app";
import { commonTypes } from "@app/common";
import { useContext } from "react";
import { useMutation, useQuery } from "react-query";
import { AuthContext } from "./components/AuthContext";
import { QueryKeys } from "./constants/QueryKeys";
import {
    getAuthenticatedSessionUser,
    hasAuthenticatedSession,
    login,
    logout,
    signUpInPublisher,
    verifyOneTimeLoginToken,
} from "./queries";

export const useSignUpInPublisher = () => {
    return useMutation(signUpInPublisher, {
        onSuccess: (unauthenticatedUser) => {
            queryClient.setQueryData(
                QueryKeys.UnauthenticatedUserItem,
                unauthenticatedUser
            );
        },
    });
};

export const useHasAuthenticatedSession = () =>
    useQuery(QueryKeys.HasAuthenticatedSession, hasAuthenticatedSession, {
        retry: false,
        refetchOnWindowFocus: true,
        refetchInterval: false,
    });

export const useLogout = () => {
    return useMutation(logout, {
        onSuccess: () => {
            queryClient.invalidateQueries([
                QueryKeys.HasAuthenticatedSession,
                QueryKeys.AuthenticatedUserItem,
                QueryKeys.UnauthenticatedUserItem,
            ]);

            queryClient.setQueryData(
                QueryKeys.AuthenticatedUserItem,
                undefined
            );
        },
    });
};

export const useLogin = () => {
    return useMutation(login, {
        onSuccess: (authenticatedUser) => {
            queryClient.invalidateQueries([QueryKeys.HasAuthenticatedSession]);

            queryClient.setQueryData(
                QueryKeys.AuthenticatedUserItem,
                authenticatedUser
            );
        },
    });
};

export const useVerifyOneTimeLoginToken = () => {
    return useMutation(verifyOneTimeLoginToken, {
        onSuccess: (authenticatedUser) => {
            queryClient.invalidateQueries([QueryKeys.HasAuthenticatedSession]);

            queryClient.invalidateQueries([QueryKeys.AuthenticatedUserItem]);

            // queryClient.setQueryData(
            //     QueryKeys.AuthenticatedUserItem,
            //     authenticatedUser
            // );
        },
    });
};

export const useGetAuthenticatedSessionUser = (
    options?: commonTypes.IUseGetOptions
) => {
    return useQuery(
        QueryKeys.AuthenticatedUserItem,
        getAuthenticatedSessionUser,
        {
            ...options,
            onError: () => {
                queryClient.setQueryData(QueryKeys.AuthenticatedUserItem, null);
            },
        }
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
};
