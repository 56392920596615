// import {
//     ChartTooltipFrame,
//     ChartTooltipRow,
// } from "components/chart-elements/common/ChartTooltip";

export interface DonutChartTooltipProps {
    active: boolean | undefined;
    payload: any;
    // valueFormatter: ValueFormatter;
}

export const DonutChartTooltip = ({
    active,
    payload,
}: // valueFormatter,
DonutChartTooltipProps) => {
    if (active && payload?.[0]) {
        const payloadRow = payload?.[0];
        console.log(`bg-[${payloadRow.payload.fill}]`);
        return (
            <div className="border bg-white shadow-md rounded-md px-2 py-1.5 flex">
                <div
                    className={`h-2.5 w-2.5 rounded-full bg-[${payloadRow.payload.fill}]`}
                />{" "}
                {payloadRow.name}
            </div>
        );
    }
    return null;
};
