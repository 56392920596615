import { appHooks } from "@app/app";
import { authTypes } from "@app/auth";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PlainFooter } from "./footer/PlainFooter";
import { Topbar } from "./topbar/Topbar";

export interface IContentWithNavigationTopbarLayoutProps {
    authenticatedUser: authTypes.IAuthenticatedUser;
}

export const ContentWithNavigationTopbarLayout = ({
    authenticatedUser,
}: IContentWithNavigationTopbarLayoutProps) => {
    const appState = appHooks.useAppState();
    const { company, guestCompany } = appState.state;

    return (
        <div className="min-h-full bg-white">
            <Topbar
                authenticatedUser={authenticatedUser}
                company={company || guestCompany}
            />

            <div className="h-full bg-background">
                <Suspense>
                    <Outlet />
                </Suspense>
            </div>

            <PlainFooter />
        </div>
    );
};
