"use client";

import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";
import * as React from "react";

import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

export type TRadioGroupProps = React.ComponentPropsWithoutRef<
    typeof RadioGroupPrimitive.Root
>;

const RadioGroup: FC<TRadioGroupProps> = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Root>,
    TRadioGroupProps
>(({ className, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Root
            className={classMerge("grid gap-2", className)}
            {...props}
            ref={ref}
        />
    );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

export type TRadioGroupItemProps = React.ComponentPropsWithoutRef<
    typeof RadioGroupPrimitive.Item
>;

const RadioGroupItem: FC<TRadioGroupItemProps> = React.forwardRef<
    React.ElementRef<typeof RadioGroupPrimitive.Item>,
    TRadioGroupItemProps
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ className, children, ...props }, ref) => {
    return (
        <RadioGroupPrimitive.Item
            ref={ref}
            className={classMerge(
                "aspect-square h-4 w-4 rounded-full border border-theme-primary text-theme-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-theme-ring-primary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className
            )}
            {...props}
        >
            <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
                <Circle className="h-2.5 w-2.5 fill-current text-current" />
            </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
    );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
