import { classMerge } from "@ui/utils";
import React from "react";

export interface IFlashyHeaderProps {
    children: React.ReactNode | React.ReactNode[];
    className?: string;
}

export const FlashyHeader = ({ children, className }: IFlashyHeaderProps) => (
    <h1
        className={classMerge(
            "bg-gradient-to-r from-theme-gradient-primary-from to-theme-gradient-primary-to leading-[45.60px] inline-block text-transparent bg-clip-text text-4xl font-black break-words",
            className
        )}
    >
        {children}
    </h1>
);
