/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { FC, Fragment, useState } from "react";
import {
    CartesianGrid,
    Dot,
    Legend,
    Line,
    LineChart as ReChartsLineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import { classMerge } from "../../utils/styleUtils";
import { BaseChartProps } from "../common/types";
import { getYAxisDomain, hasOnlyOneValueForThisKey } from "../common/utils";

export type CurveType = "linear" | "natural" | "monotone" | "step";

export interface ILineChartProps extends BaseChartProps {
    curveType?: CurveType;
    connectNulls?: boolean;
}

interface ActiveDot {
    index?: number;
    dataKey?: string;
}

export const LineChart: FC<ILineChartProps> = React.forwardRef<
    HTMLDivElement,
    ILineChartProps
>((props, ref) => {
    const {
        data = [],
        categories = [],
        index,
        colors = [],
        valueFormatter = (value: any) => value,
        startEndOnly = false,
        showXAxis = true,
        showYAxis = true,
        yAxisWidth = 56,
        animationDuration = 900,
        showAnimation = false,
        showTooltip = true,
        showLegend = true,
        showGridLines = true,
        autoMinValue = false,
        curveType = "linear",
        minValue,
        maxValue,
        connectNulls = false,
        allowDecimals = true,
        // noDataText,
        className,
        onValueChange,
        // customTooltip,
        ...other
    } = props;
    // const CustomTooltip = customTooltip;
    const [legendHeight] = useState(60);
    const [activeDot, setActiveDot] = useState<ActiveDot | undefined>(
        undefined
    );
    const [activeLegend, setActiveLegend] = useState<string | undefined>(
        undefined
    );

    const yAxisDomain = getYAxisDomain(autoMinValue, minValue, maxValue);
    const hasOnValueChange = !!onValueChange;

    function onDotClick(itemData: any, event: React.MouseEvent) {
        event.stopPropagation();

        if (!hasOnValueChange) return;
        if (
            (itemData.index === activeDot?.index &&
                itemData.dataKey === activeDot?.dataKey) ||
            (hasOnlyOneValueForThisKey(data, itemData.dataKey) &&
                activeLegend &&
                activeLegend === itemData.dataKey)
        ) {
            setActiveLegend(undefined);
            setActiveDot(undefined);
            onValueChange?.(null);
        } else {
            setActiveLegend(itemData.dataKey);
            setActiveDot({
                index: itemData.index,
                dataKey: itemData.dataKey,
            });
            onValueChange?.({
                eventType: "dot",
                categoryClicked: itemData.dataKey,
                ...itemData.payload,
            });
        }
    }

    function onCategoryClick(dataKey: string) {
        if (!hasOnValueChange) return;
        if (
            (dataKey === activeLegend && !activeDot) ||
            (hasOnlyOneValueForThisKey(data, dataKey) &&
                activeDot &&
                activeDot.dataKey === dataKey)
        ) {
            setActiveLegend(undefined);
            onValueChange?.(null);
        } else {
            setActiveLegend(dataKey);
            onValueChange?.({
                eventType: "category",
                categoryClicked: dataKey,
            });
        }
        setActiveDot(undefined);
    }

    return (
        <div
            ref={ref}
            className={classMerge("w-full h-80", className)}
            {...other}
        >
            <ResponsiveContainer className="h-full w-full">
                {data?.length ? (
                    <ReChartsLineChart
                        data={data}
                        onClick={
                            hasOnValueChange && (activeLegend || activeDot)
                                ? () => {
                                      setActiveDot(undefined);
                                      setActiveLegend(undefined);
                                      onValueChange?.(null);
                                  }
                                : undefined
                        }
                    >
                        {showGridLines ? (
                            <CartesianGrid
                                className="stroke-1 stroke-secondary-foreground/5"
                                horizontal={true}
                                vertical={false}
                            />
                        ) : null}
                        <XAxis
                            hide={!showXAxis}
                            dataKey={index}
                            interval="preserveStartEnd"
                            tick={{ transform: "translate(0, 6)" }}
                            ticks={
                                startEndOnly
                                    ? [
                                          data[0][index],
                                          data[data.length - 1][index],
                                      ]
                                    : undefined
                            }
                            fill=""
                            stroke=""
                            className="text-xs text-secondary-foreground"
                            tickLine={false}
                            axisLine={false}
                            padding={{ left: 10, right: 10 }}
                            minTickGap={5}
                        />
                        <YAxis
                            width={yAxisWidth}
                            hide={!showYAxis}
                            axisLine={false}
                            tickLine={false}
                            type="number"
                            domain={yAxisDomain as AxisDomain}
                            tick={{ transform: "translate(-3, 0)" }}
                            fill=""
                            stroke=""
                            className="text-xs font-medium"
                            tickFormatter={valueFormatter}
                            allowDecimals={allowDecimals}
                        />
                        <Tooltip
                            wrapperStyle={{ outline: "none" }}
                            isAnimationActive={false}
                            cursor={{ stroke: "#d1d5db", strokeWidth: 1 }}
                            content={
                                showTooltip ? (
                                    ({ active, payload, label }) => (
                                        <div>test</div>
                                    )
                                ) : (
                                    <></>
                                )
                            }
                            position={{ y: 0 }}
                        />

                        {showLegend ? (
                            <Legend
                                verticalAlign="top"
                                height={legendHeight}
                                content={({ payload }) => (
                                    <div className="flex gap-x-4 justify-end">
                                        {payload?.map((test, index) => {
                                            return (
                                                <div
                                                    className="flex items-center text-sm"
                                                    key={`${test.id}-${index}`}
                                                >
                                                    <div
                                                        className={`h-4 w-4 bg-blue-600 rounded-full `}
                                                    />
                                                    {test.value}
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            />
                        ) : null}
                        {categories.map((category, index) => (
                            <Line
                                key={`${category}-${index}`}
                                className={`${colors[index]}`}
                                strokeOpacity={
                                    activeDot ||
                                    (activeLegend && activeLegend !== category)
                                        ? 0.3
                                        : 1
                                }
                                activeDot={(props: any) => {
                                    const {
                                        cx,
                                        cy,
                                        stroke,
                                        strokeLinecap,
                                        strokeLinejoin,
                                        strokeWidth,
                                    } = props;
                                    return (
                                        <Dot
                                            className={classMerge(
                                                "stroke-background dark:stroke-dark-background",
                                                onValueChange
                                                    ? "cursor-pointer"
                                                    : ""
                                            )}
                                            cx={cx}
                                            cy={cy}
                                            r={5}
                                            fill=""
                                            stroke={stroke}
                                            strokeLinecap={strokeLinecap}
                                            strokeLinejoin={strokeLinejoin}
                                            strokeWidth={strokeWidth}
                                            onClick={(
                                                dotProps: unknown,
                                                event
                                            ) => onDotClick(props, event)}
                                        />
                                    );
                                }}
                                dot={(props: any) => {
                                    const {
                                        stroke,
                                        strokeLinecap,
                                        strokeLinejoin,
                                        strokeWidth,
                                        cx,
                                        cy,
                                        index,
                                    } = props;

                                    if (
                                        (hasOnlyOneValueForThisKey(
                                            data,
                                            category
                                        ) &&
                                            !(
                                                activeDot ||
                                                (activeLegend &&
                                                    activeLegend !== category)
                                            )) ||
                                        (activeDot?.index === index &&
                                            activeDot?.dataKey === category)
                                    ) {
                                        return (
                                            <Dot
                                                key={index}
                                                cx={cx}
                                                cy={cy}
                                                r={5}
                                                stroke={stroke}
                                                fill=""
                                                strokeLinecap={strokeLinecap}
                                                strokeLinejoin={strokeLinejoin}
                                                strokeWidth={strokeWidth}
                                                className={classMerge(
                                                    "stroke-background dark:stroke-dark-background",
                                                    onValueChange
                                                        ? "cursor-pointer"
                                                        : ""
                                                )}
                                            />
                                        );
                                    }
                                    return <Fragment key={index}></Fragment>;
                                }}
                                name={category}
                                type={curveType}
                                dataKey={category}
                                stroke=""
                                strokeWidth={2}
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                isAnimationActive={showAnimation}
                                animationDuration={animationDuration}
                                connectNulls={connectNulls}
                            />
                        ))}
                        {onValueChange
                            ? categories.map((category) => (
                                  <Line
                                      className={classMerge("cursor-pointer")}
                                      strokeOpacity={0}
                                      key={category}
                                      name={category}
                                      type={curveType}
                                      dataKey={category}
                                      stroke="transparent"
                                      fill="transparent"
                                      legendType="none"
                                      tooltipType="none"
                                      strokeWidth={12}
                                      connectNulls={connectNulls}
                                      onClick={(props: any, event) => {
                                          event.stopPropagation();
                                          const { name } = props;
                                          onCategoryClick(name);
                                      }}
                                  />
                              ))
                            : null}
                    </ReChartsLineChart>
                ) : (
                    <p>noDataText</p>
                )}
            </ResponsiveContainer>
        </div>
    );
});

LineChart.displayName = "LineChart";
