import { cva } from "class-variance-authority";

export const flexVariants = cva("", {
    variants: {
        justifyContent: {
            "justify-start": "justify-start",
            "justify-end": "justify-end",
            "justify-center": "justify-center",
            "justify-between": "justify-between",
            "justify-around": "justify-around",
            "justify-evenly": "justify-evenly",
        },
        alignItems: {
            "items-start": "items-start",
            "items-end": "items-end",
            "items-center": "items-center",
            "items-baseline": "items-baseline",
            "items-stretch": "items-stretch",
        },
        direction: {
            "flex-row": "flex-row",
            "flex-col": "flex-col",
            "row-reverse": "flex-row-reverse",
            "col-reverse": "flex-col-reverse",
        },
    },
    defaultVariants: {
        direction: "flex-row",
        justifyContent: "justify-between",
        alignItems: "items-center",
    },
});
