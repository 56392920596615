import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";
import * as React from "react";
import { FC, ReactElement } from "react";
import { Spinner } from "../../components/spinner/Spinner";
import { classMerge } from "../../utils/styleUtils";
import { DEFAULT_VARIANT_SIZE, buttonVariants } from "./buttonVariants";

export interface IButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
}

/**
 * Displays a button or a component that looks like a button.
 *
 * @see https://ui.shadcn.com/docs/components/button
 */
export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
    ({ className, variant, size, asChild = false, ...props }, ref) => {
        const Cmp = asChild ? Slot : "button";

        return (
            <Cmp
                className={classMerge(
                    buttonVariants({ variant, size, className })
                )}
                ref={ref}
                {...props}
            />
        );
    }
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Button as any).displayName = "Button";

interface IDecoratedButtonProps extends Omit<IButtonProps, "prefix"> {
    prefix?: ReactElement | ReactElement[];
    suffix?: ReactElement | ReactElement[];
    prefixClassName?: string;
    suffixClassName?: string;
}

const DecoratedButton: FC<IDecoratedButtonProps> = React.forwardRef<
    HTMLInputElement,
    IDecoratedButtonProps
>(
    ({
        prefix,
        suffix,
        prefixClassName,
        suffixClassName,
        children,
        ...buttonProps
    }: IDecoratedButtonProps) => {
        const hasPrefix = !!prefix;
        const hasSuffix = !!suffix;

        return (
            <Button {...buttonProps}>
                {hasPrefix && (
                    <div
                        className={classMerge(
                            "mr-2",
                            buttonProps.className,
                            prefixClassName
                        )}
                    >
                        {prefix}
                    </div>
                )}
                {children}
                {hasSuffix && (
                    <div
                        className={classMerge(
                            "ml-2",
                            buttonProps.className,
                            suffixClassName
                        )}
                    >
                        {suffix}
                    </div>
                )}
            </Button>
        );
    }
);

export { DecoratedButton, type IDecoratedButtonProps };

interface IDecoratedLoaderButtonProps
    extends Omit<IDecoratedButtonProps, "prefix" | "suffix"> {
    position?: "prefix" | "suffix";
    prefixClassName?: string;
    suffixClassName?: string;
    isLoading: boolean;
    defaultLoaderClassName?: string;
    loader?: string | ReactElement;
}

const buttonSizeToIconClassNamesMap = {
    default: "w-5 h-5",
    sm: "w-3 h-3",
    lg: "w-6 h-6",
    icon: "",
};

const DecoratedLoaderButton: FC<IDecoratedLoaderButtonProps> = React.forwardRef<
    HTMLInputElement,
    IDecoratedLoaderButtonProps
>(
    ({
        position = "suffix",
        isLoading,
        loader,
        size = DEFAULT_VARIANT_SIZE,
        defaultLoaderClassName,
        ...buttonProps
    }: IDecoratedLoaderButtonProps) => {
        const targetPropKey = position === "prefix" ? "prefix" : "suffix";
        const additionalProps = isLoading
            ? {
                  [targetPropKey]: loader ? (
                      loader
                  ) : (
                      <Spinner
                          className={classMerge(
                              "Spinner text-white",
                              size
                                  ? buttonSizeToIconClassNamesMap?.[size]
                                  : null,
                              defaultLoaderClassName
                          )}
                      />
                  ),
              }
            : {};

        return (
            <DecoratedButton
                {...buttonProps}
                {...additionalProps}
                disabled={isLoading || buttonProps.disabled}
                className={classMerge("transition-all", buttonProps.className)}
            />
        );
    }
);

export { DecoratedLoaderButton, type IDecoratedLoaderButtonProps };
