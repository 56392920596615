"use client";

import * as MenubarPrimitive from "@radix-ui/react-menubar";
import { Check, ChevronRight, Circle } from "lucide-react";
import * as React from "react";
import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";

const MenubarMenu = MenubarPrimitive.Menu;

const MenubarGroup = MenubarPrimitive.Group;

const MenubarPortal = MenubarPrimitive.Portal;

const MenubarSub = MenubarPrimitive.Sub;

const MenubarRadioGroup = MenubarPrimitive.RadioGroup;

export type TMenubarProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Root
>;

const Menubar: FC<TMenubarProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Root>,
    TMenubarProps
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Root
        ref={ref}
        className={classMerge(
            "flex h-10 items-center space-x-1 rounded-md border bg-background p-1",
            className
        )}
        {...props}
    />
));
Menubar.displayName = MenubarPrimitive.Root.displayName;

export type TMenubarTriggerProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Trigger
>;
const MenubarTrigger: FC<TMenubarTriggerProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Trigger>,
    TMenubarTriggerProps
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Trigger
        ref={ref}
        className={classMerge(
            "flex cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground",
            className
        )}
        {...props}
    />
));
MenubarTrigger.displayName = MenubarPrimitive.Trigger.displayName;

export type TMenubarSubTriggerProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.SubTrigger
> & {
    inset?: boolean;
};
const MenubarSubTrigger: FC<TMenubarSubTriggerProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.SubTrigger>,
    TMenubarSubTriggerProps
>(({ className, inset, children, ...props }, ref) => (
    <MenubarPrimitive.SubTrigger
        ref={ref}
        className={classMerge(
            "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground",
            inset && "pl-8",
            className
        )}
        {...props}
    >
        {children}
        <ChevronRight className="ml-auto h-4 w-4" />
    </MenubarPrimitive.SubTrigger>
));
MenubarSubTrigger.displayName = MenubarPrimitive.SubTrigger.displayName;

export type TMenubarSubContentProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.SubContent
>;

const MenubarSubContent: FC<TMenubarSubContentProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.SubContent>,
    TMenubarSubContentProps
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.SubContent
        ref={ref}
        className={classMerge(
            "z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className
        )}
        {...props}
    />
));
MenubarSubContent.displayName = MenubarPrimitive.SubContent.displayName;

export type TMenubarContentProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Content
>;

const MenubarContent: FC<TMenubarContentProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Content>,
    TMenubarContentProps
>(
    (
        {
            className,
            align = "start",
            alignOffset = -4,
            sideOffset = 8,
            ...props
        },
        ref
    ) => (
        <MenubarPrimitive.Portal>
            <MenubarPrimitive.Content
                ref={ref}
                align={align}
                alignOffset={alignOffset}
                sideOffset={sideOffset}
                className={classMerge(
                    "z-50 min-w-[12rem] overflow-hidden rounded-md border bg-popover p-1 text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
                    className
                )}
                {...props}
            />
        </MenubarPrimitive.Portal>
    )
);
MenubarContent.displayName = MenubarPrimitive.Content.displayName;

export type TMenubarItemProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Item
> & {
    inset?: boolean;
};
const MenubarItem: FC<TMenubarItemProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Item>,
    TMenubarItemProps
>(({ className, inset, ...props }, ref) => (
    <MenubarPrimitive.Item
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            inset && "pl-8",
            className
        )}
        {...props}
    />
));
MenubarItem.displayName = MenubarPrimitive.Item.displayName;

export type TMenubarCheckboxItemProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.CheckboxItem
>;

const MenubarCheckboxItem: FC<TMenubarCheckboxItemProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.CheckboxItem>,
    TMenubarCheckboxItemProps
>(({ className, children, checked, ...props }, ref) => (
    <MenubarPrimitive.CheckboxItem
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        checked={checked}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <MenubarPrimitive.ItemIndicator>
                <Check className="h-4 w-4" />
            </MenubarPrimitive.ItemIndicator>
        </span>
        {children}
    </MenubarPrimitive.CheckboxItem>
));
MenubarCheckboxItem.displayName = MenubarPrimitive.CheckboxItem.displayName;

export type TMenubarRadioItemProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.RadioItem
>;

const MenubarRadioItem: FC<TMenubarRadioItemProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.RadioItem>,
    TMenubarRadioItemProps
>(({ className, children, ...props }, ref) => (
    <MenubarPrimitive.RadioItem
        ref={ref}
        className={classMerge(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
            className
        )}
        {...props}
    >
        <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
            <MenubarPrimitive.ItemIndicator>
                <Circle className="h-2 w-2 fill-current" />
            </MenubarPrimitive.ItemIndicator>
        </span>
        {children}
    </MenubarPrimitive.RadioItem>
));
MenubarRadioItem.displayName = MenubarPrimitive.RadioItem.displayName;

export type TMenubarLabelProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Label
> & {
    inset?: boolean;
};
const MenubarLabel: FC<TMenubarLabelProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Label>,
    TMenubarLabelProps
>(({ className, inset, ...props }, ref) => (
    <MenubarPrimitive.Label
        ref={ref}
        className={classMerge(
            "px-2 py-1.5 text-sm font-semibold",
            inset && "pl-8",
            className
        )}
        {...props}
    />
));
MenubarLabel.displayName = MenubarPrimitive.Label.displayName;

export type TMenubarSeparatorProps = React.ComponentPropsWithoutRef<
    typeof MenubarPrimitive.Separator
>;

const MenubarSeparator: FC<TMenubarSeparatorProps> = React.forwardRef<
    React.ElementRef<typeof MenubarPrimitive.Separator>,
    TMenubarSeparatorProps
>(({ className, ...props }, ref) => (
    <MenubarPrimitive.Separator
        ref={ref}
        className={classMerge("-mx-1 my-1 h-px bg-muted", className)}
        {...props}
    />
));
MenubarSeparator.displayName = MenubarPrimitive.Separator.displayName;

const MenubarShortcut = ({
    className,
    ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
    return (
        <span
            className={classMerge(
                "ml-auto text-xs tracking-widest text-muted-foreground",
                className
            )}
            {...props}
        />
    );
};
MenubarShortcut.displayname = "MenubarShortcut";

export {
    Menubar,
    MenubarCheckboxItem,
    MenubarContent,
    MenubarGroup,
    MenubarItem,
    MenubarLabel,
    MenubarMenu,
    MenubarPortal,
    MenubarRadioGroup,
    MenubarRadioItem,
    MenubarSeparator,
    MenubarShortcut,
    MenubarSub,
    MenubarSubContent,
    MenubarSubTrigger,
    MenubarTrigger,
};
