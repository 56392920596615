import { ComponentType, lazy } from "react";

export type TReactLazyPreloadImportStatement = () => Promise<{
    default: ComponentType<unknown>;
}>;

/**
 * reactLazyPreload
 * @more - https://blog.maximeheckel.com/posts/preloading-views-with-react/
 */
export const reactLazyPreload = (
    importStatement: TReactLazyPreloadImportStatement
) => {
    const Component = lazy(importStatement);

    return Object.assign(Component, { preload: importStatement });
};
