import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { config } from "../config";
import { axios } from "./axios";

(window as any).Pusher = Pusher;

export const laravelEcho = new Echo({
    broadcaster: "pusher",
    forceTLS: true,
    key: config.pusherAppKey,
    cluster: config.pusherAppCluster,
    authorizer: (channel: any) => ({
        authorize: (socketId: any, callback: any) => {
            axios
                .post("broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name,
                })
                .then((response) => callback(false, response.data))
                .catch((error: Error) => callback(true, error));
        },
    }),
});

(window as any).laravelEcho = laravelEcho;

laravelEcho.connector.pusher.connection.bind("connected", () => {
    console.log("connected");
});

laravelEcho.connector.pusher.connection.bind("disconnected", () => {
    console.warn("disconnected");
});
