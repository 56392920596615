import React, { FC } from "react";

import { classMerge } from "../../utils/styleUtils";

export type TCardProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Displays a card with header, content, and footer.
 *
 * @see https://ui.shadcn.com/docs/components/card
 */
const Card: FC<TCardProps> = React.forwardRef<HTMLDivElement, TCardProps>(
    ({ className, ...props }, ref) => (
        <div
            ref={ref}
            className={classMerge(
                "w-full rounded-lg border bg-card text-card-foreground shadow-sm",
                className
            )}
            {...props}
        />
    )
);
Card.displayName = "Card";

export type TCardHeaderProps = React.HTMLAttributes<HTMLDivElement>;

const CardHeader: FC<TCardHeaderProps> = React.forwardRef<
    HTMLDivElement,
    TCardHeaderProps
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={classMerge("flex flex-col space-y-1.5 p-6", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

export type TCardTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

const CardTitle: FC<TCardTitleProps> = React.forwardRef<
    HTMLParagraphElement,
    TCardTitleProps
>(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={classMerge(
            "text-2xl font-semibold leading-none tracking-tight",
            className
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

export type TCardDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

const CardDescription: FC<TCardDescriptionProps> = React.forwardRef<
    HTMLParagraphElement,
    TCardDescriptionProps
>(({ className, ...props }, ref) => (
    <p
        ref={ref}
        className={classMerge("text-sm text-muted-foreground", className)}
        {...props}
    />
));
CardDescription.displayName = "CardDescription";

export type TCardContentProps = React.HTMLAttributes<HTMLDivElement>;

const CardContent: FC<TCardContentProps> = React.forwardRef<
    HTMLDivElement,
    TCardContentProps
>(({ className, ...props }, ref) => (
    <div ref={ref} className={classMerge("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

export type TCardFooterProps = React.HTMLAttributes<HTMLDivElement>;

const CardFooter: FC<TCardFooterProps> = React.forwardRef<
    HTMLDivElement,
    TCardFooterProps
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={classMerge("flex items-center p-6 pt-0", className)}
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

export {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
};
