import { IAuthContext } from "@app/auth";
import { CompanyIntegrationOverviewPage } from "@app/integrations/pages/CompanyIntegrationOverviewPage";
import { Roles } from "@app/rbac";
import { Fragment, lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import { WithRoleAccessCheck } from "../Routes";
import { ContentWithNavigationSidebarLayout } from "../layouts/ContentWithNavigationSidebarLayout";
import { FullScreenLayout } from "../layouts/FullScreenLayout";
import { SettingPageWithNavigationSidebarLayout } from "../layouts/SettingPageWithNavigationSidebarLayout";

const EmployerLoginPage = lazy(() =>
    import("@app/employer/pages/EmployerLoginPage").then((module) => ({
        default: module.EmployerLoginPage,
    }))
);

const DashboardOverview = lazy(() =>
    import("@app/dashboard/pages/DashboardOverview").then((module) => ({
        default: module.DashboardOverview,
    }))
);

const EmployerCompanyVacancyOverviewPage = lazy(() =>
    import("@app/company/pages/EmployerCompanyVacancyOverviewPage").then(
        (module) => ({
            default: module.EmployerCompanyVacancyOverviewPage,
        })
    )
);

const CompanyLeadOverviewPage = lazy(() =>
    import("@app/lead/pages/CompanyLeadOverviewPage").then((module) => ({
        default: module.CompanyLeadOverviewPage,
    }))
);

const CompanyReportOverviewPage = lazy(() =>
    import("@app/report/pages/CompanyReportOverviewPage").then((module) => ({
        default: module.CompanyReportOverviewPage,
    }))
);

const EmployerCompanyVacancyItemPage = lazy(() =>
    import("@app/company/pages/EmployerCompanyVacancyItemPage").then(
        (module) => ({
            default: module.EmployerCompanyVacancyItemPage,
        })
    )
);

const CompanyUserOverviewPage = lazy(() =>
    import("@app/user/pages/CompanyUserOverviewPage").then((module) => ({
        default: module.CompanyUserOverviewPage,
    }))
);

const UserCompanyItemPage = lazy(() =>
    import("@app/user/pages/UserCompanyItemPage").then((module) => ({
        default: module.UserCompanyItemPage,
    }))
);

const UserCompanyOverviewPage = lazy(() =>
    import("@app/company/pages/UserCompanyOverviewPage").then((module) => ({
        default: module.UserCompanyOverviewPage,
    }))
);

const AdminUsersOverviewPage = lazy(() =>
    import("@app/user/pages/AdminUsersOverviewPage").then((module) => ({
        default: module.AdminUsersOverviewPage,
    }))
);

export const employerRoutes = ({ auth }: { auth: IAuthContext }) => (
    <Route>
        {!auth.authenticatedUser ? (
            <Fragment>
                <Route element={<FullScreenLayout />}>
                    <Route path="auth">
                        <Route path="login" element={<EmployerLoginPage />} />
                    </Route>
                </Route>
                {/* <Route path="*" element={<div>404 not found</div>} /> */}
                <Route path="*" element={<Navigate to={"/auth/login"} />} />
                <Route index element={<Navigate to={"/auth/login"} />} />
            </Fragment>
        ) : (
            <Fragment>
                <Route
                    element={<WithRoleAccessCheck roles={[Roles.Employer]} />}
                >
                    <Route
                        element={
                            <ContentWithNavigationSidebarLayout
                                authenticatedUser={auth.authenticatedUser}
                            />
                        }
                    >
                        <Route
                            path="users"
                            element={<CompanyUserOverviewPage />}
                        />
                        <Route path="companies">
                            <Route
                                path="leads"
                                element={<CompanyLeadOverviewPage />}
                            />
                            <Route
                                path="reports"
                                element={<CompanyReportOverviewPage />}
                            />
                            <Route
                                path="vacancies"
                                element={<EmployerCompanyVacancyOverviewPage />}
                            />
                            <Route
                                path="vacancies/:vacancyId"
                                element={<EmployerCompanyVacancyItemPage />}
                            />
                        </Route>
                        <Route
                            path="admin"
                            // element={
                            //     <ul>
                            //         <li>
                            //             <Link to="#">Admin</Link>

                            //             <ul>
                            //                 <li>
                            //                     <Link to="/admin/users">
                            //                         Users
                            //                     </Link>
                            //                 </li>
                            //                 <li>
                            //                     <Link to="/admin/companies">
                            //                         Companies
                            //                     </Link>
                            //                 </li>
                            //                 <li>
                            //                     <Link to="/admin/vacancies">
                            //                         Vacancies
                            //                     </Link>
                            //                 </li>
                            //             </ul>
                            //         </li>
                            //     </ul>
                            // }
                        >
                            <Route
                                path="users"
                                element={<AdminUsersOverviewPage />}
                            />
                            <Route
                                path="companies"
                                element={<span>Admin companies overview</span>}
                            />
                            <Route
                                path="vacancies"
                                element={<span>Admin vacancies overview</span>}
                            />
                        </Route>
                        <Route index element={<DashboardOverview />} />
                    </Route>

                    <Route element={<SettingPageWithNavigationSidebarLayout />}>
                        <Route
                            path="settings/integrations"
                            element={<CompanyIntegrationOverviewPage />}
                        />
                        <Route
                            path="settings/*"
                            element={<UserCompanyOverviewPage />}
                        />
                    </Route>

                    <Route index element={<Navigate to={"/"} />} />
                    <Route path="*" element={<div></div>} />

                    {/*
                    <Route element={<ContentWithNavigationSidebarLayout />}>
                        <Route index element={<DashboardOverview />} />
                        <Route path="companies/:companyId/">
                            <Route
                                path="vacancies"
                                element={<CompanyVacancyOverviewPage />}
                            />
                            <Route
                                path="vacancies/:vacancyId"
                                element={<CompanyVacancyItemPage />}
                            />
                        </Route>
                        <Route path="users/:userId/">
                            <Route
                                path="companies/:companyId"
                                element={<UserCompanyItemPage />}
                            />

                            <Route
                                path="companies"
                                element={<UserCompanyOverviewPage />}
                            />
                        </Route>
                    </Route>
                    <Route path="*" element={<div>404 Not Found</div>} /> */}
                </Route>
            </Fragment>
        )}
    </Route>
);
