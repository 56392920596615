import * as React from "react";
import { ILoginProps, IVerifyOneTimeLoginTokenProps } from "../queries";
import { IAuthenticatedUser, IUnauthenticatedUser } from "../types";

export interface IAuthContext {
    unauthenticatedUser?: IUnauthenticatedUser;
    authenticatedUser?: IAuthenticatedUser;
    hasAuthenticatedSession?: boolean;
    isInitialized: boolean;
    setUnauthenticatedUser: (user: IUnauthenticatedUser) => void;
    login: (props: ILoginProps) => Promise<IAuthenticatedUser | undefined>;
    logout: () => Promise<void>;
    verifyOneTimeLoginToken: (
        props: IVerifyOneTimeLoginTokenProps
    ) => Promise<IAuthenticatedUser>;
}

export const AuthContext = React.createContext<IAuthContext>({
    authenticatedUser: undefined,
    unauthenticatedUser: undefined,
    hasAuthenticatedSession: false,
    isInitialized: false,
    setUnauthenticatedUser: () => undefined,
    login: () => Promise.resolve(undefined),
    logout: () => Promise.resolve(undefined),
    verifyOneTimeLoginToken: () => Promise.resolve({} as IAuthenticatedUser),
});

AuthContext.displayName = "AuthContext";
