import { type VariantProps } from "class-variance-authority";
import * as React from "react";
import { FC } from "react";
import { classMerge } from "../../utils/styleUtils";
import { alertVariants } from "./alertVariants";

type TAlertVariantProps = VariantProps<typeof alertVariants>;

export type TAlertProps = React.HTMLAttributes<HTMLDivElement> &
    TAlertVariantProps;

/**
 * Displays a callout for user attention.
 *
 * @see https://ui.shadcn.com/docs/components/alert
 */
const Alert: FC<TAlertProps> = React.forwardRef<HTMLDivElement, TAlertProps>(
    ({ className, variant, ...props }, ref) => (
        <div
            ref={ref}
            role="alert"
            className={classMerge(alertVariants({ variant }), className)}
            {...props}
        />
    )
);
Alert.displayName = "Alert";

export type TAlertTitleProps = React.HTMLAttributes<HTMLHeadingElement>;

const AlertTitle: FC<TAlertTitleProps> = React.forwardRef<
    HTMLParagraphElement,
    TAlertTitleProps
>(({ className, ...props }, ref) => (
    <h5
        ref={ref}
        className={classMerge(
            "mb-1 font-medium leading-none tracking-tight",
            className
        )}
        {...props}
    />
));
AlertTitle.displayName = "AlertTitle";

export type TAlertDescriptionProps = React.HTMLAttributes<HTMLParagraphElement>;

const AlertDescription: FC<TAlertDescriptionProps> = React.forwardRef<
    HTMLParagraphElement,
    TAlertDescriptionProps
>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={classMerge("text-sm [&_p]:leading-relaxed", className)}
        {...props}
    />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription, AlertTitle, type TAlertVariantProps };
